import { useTranslation } from 'react-i18next'
import { BigWalletCard } from './big-card.react'
import { SmallWalletCard } from './small-card.react'
import { Routes } from '@/features/router'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

export const CardWallet = ({
  Type,
  className,
  config
}: {
  Type: string
  className?: string
  config?: any
}) => {
  const { t } = useTranslation(['wallet'])

  let listDataProp = null
  let cardData = {}
  const cardConfig = {
    button: { text: '' },
    rate: { unit: '%' },
    amount: { unit: '€', className: 'text-2xl lg:text-3xl text-blue-500' },
    header: { className: '' },
    body: { className: 'pt-2' }
  }
  let chartConfig = {}
  let formConfig = null
  const timestamps: number[] = [];
  const values: number[] = [];
  // console.log(config)
  switch (Type) {
    case 'total':
      // Add specific config of card
      cardConfig.button.text = t('wallet.dashboard.balance_card.total.button')
      cardData = {
        name: t('wallet.dashboard.balance_card.total.title'),
        current_price: config.totalAmount,
        price_change_percentage_24h: config.totalAmountRate,
      }
      // Chart configuration
      config?.chartData.forEach(([timestamp, value]) => {
        timestamps.push(new Date(timestamp * 1000).toISOString().split('T')[0]);
        values.push(value);
      })
      chartConfig = {
        type: 'Line', 
        data: values,
        labels: timestamps,
        config: {
          datasets: {
            fill: true,
            labelName: 'Amount'
          },
          display: {
            datalabels: true
          },
          layout: {
            padding: 40
          }
        },
        className: ''
      }
      break
    case 'repartition':
      // Add specific config of card
      cardConfig.button.text = t('wallet.dashboard.balance_card.repartition.button')
      cardConfig.amount.className = 'text-xl text-blue-500 dark:text-blue-500'
      cardConfig.body.className = 'py-4'
      cardData = {
        name: t('wallet.dashboard.balance_card.repartition.title')
      }
      // Chart configuration
      chartConfig = {
        type: 'Doughnut', 
        data: Object.values(config.data.chartData),
        labels: Object.keys(config.data.chartData),
        config: {
          labelName: 'Amount'
        }, 
        className: 'max-h-64'
      }
      // List table data
      listDataProp = config.data
      break
    case 'market':
      // Chart configuration
      chartConfig = {
        type: 'Line', 
        config: {
          datasets: {
            fill: true,
            labelName: 'Amount'
          }
        },
        className: 'max-h-24'
      }
      break
    case 'details':
      // Add specific config of card
      cardConfig.button.text = t('wallet.dashboard.balance_card.total.button')
      cardData = config?.cardData
      // Chart configuration
      config?.chartData.forEach(([timestamp, value]) => {
        timestamps.push(new Date(timestamp * 1000).toISOString().split('T')[0]);
        values.push(value);
      });
      chartConfig = {
        type: 'Line', 
        data: values,
        labels: timestamps,
        config: {
          datasets: {
            fill: true,
            borderWidth: 1,
            labelName: 'Amount'
          },
          display: {
            datalabels: true
          },
          layout: {
            padding: 40
          }
        }
      }
      break      
    case 'buy':
      formConfig = {'unit': '€', "data": config.data, 'kypay': {'amount': '200000'}, 'type': 'buy'}
      break
    case 'sell':
      formConfig = {'unit': '€', "data": config.data, 'kypay': {'amount': '200000'}, 'type': 'sell'}
      break
    case 'convert':
      formConfig = {'unit': '€', "data": config.data, 'kypay': {'amount': '200000'}, 'type': 'convert'}
      break
    case 'transfer':
      formConfig = {'unit': '€', "data": config.data, 'kypay': {'amount': '200000'}, 'type': 'transfer'}
      break
  }

  return (
    <>
      {(() => {
        switch(Type) {
          case 'market':
            return (
              <>
                {config && (
                  <div className={twMerge(config.format === 'list' ? "flex flex-col space-y-2" : "col-span-3 grid grid-cols-3 gap-4 w-full", config?.className)}>
                    {(config.show ? config.data.slice(0, config.show) : config.data).map((item, index) => {
                      const cardData = { ...item, isFavorite: (item.symbol.length > 3) }
                      return (
                        <SmallWalletCard
                          key={index}
                          data={cardData}
                          config={cardConfig}
                          className={className}
                          chart={chartConfig}
                        />
                      );
                    })}
                    {config.show && (
                      <Link to={Routes.wallet_crypto} className="view-more text-end text-blue-500 underline underline-offset-1">
                        {t('wallet.buy.label.view_more')}
                      </Link>
                    )}
                  </div>
                )}
              </>
            )
          default:
            return (
              <BigWalletCard
                data={cardData}
                config={cardConfig}
                className={className}
                chart={chartConfig}
                listData= {listDataProp}
                formConfig={formConfig}
              />
            )
        }
      })()}
    </>
  );
}
