import { fetchServer } from '@/features/utils/fetch'
import {
  CurrencyList,
  PortfolioList,
  sparklineDataProps,
  sparklineTotalDataProps,
  OrdersDataProps,
  sparklineDataReponse
} from '../types'

export class WalletApiClient {

  static async getCurrencyList(): Promise<CurrencyList> {
    const response = await fetchServer('/olkywallet/crypto/currencies', {
      method: 'GET',
      reloadOn401: false,
      mainRoot: true
    })
    return (await response.json()) as CurrencyList
  }

  static async getPortfolioList(): Promise<PortfolioList> {
    const response = await fetchServer('/olkywallet/balance/portfolio', {
      method: 'GET',
      reloadOn401: false,
      mainRoot: true
    })
    return (await response.json()) as PortfolioList
  }
  
  static async sparklineData(
    sendData: sparklineDataProps
  ): Promise<sparklineDataReponse> {
    const response = await fetchServer('/olkywallet/crypto/sparkline', {
      method: 'POST',
      body: sendData,
      reloadOn401: false,
      mainRoot: true
    })
    const data = (await response.json()) as sparklineDataReponse
    return data
  }
  
  static async sparklineTotalData(
    sendData: sparklineTotalDataProps
  ): Promise<sparklineDataReponse> {
    const response = await fetchServer('/olkywallet/crypto/sparkline/total', {
      method: 'POST',
      body: sendData,
      reloadOn401: false,
      mainRoot: true
    })
    const data = (await response.json()) as sparklineDataReponse
    return data
  }
  
  static async ordersData(
    sendData: OrdersDataProps
  ): Promise<sparklineDataReponse> {
    const response = await fetchServer('/olkywallet/balance/orders/'+sendData.status, {
      method: 'GET',
      reloadOn401: false,
      mainRoot: true
    })
    return (await response.json()) as sparklineDataReponse
  }

}
