import { useTranslation } from 'react-i18next'
import { KYCSessionIFrame } from '@/molecules/kyc-session-iframe/index.react'
import {
  CreateKYCDocument,
  KYCSession,
  KYCSessionScenario,
  KYCSessionType
} from '@/features/kyc/types'
import { useKYC } from '@/features/kyc/hooks/use-kyc'
import { UserType } from '@/features/user/types'
import { useCallback, useEffect, useState } from 'react'
import Loader from '@/atoms-react/loader/Loader.react'

type QuestionScenarioProps = {
  user?: UserType
  onValidated: () => void
}
//
export const QuestionScenario = ({ user, onValidated }: QuestionScenarioProps) => {
  const { i18n } = useTranslation(['atoms'])

  const { createKYCSessionMutation } = useKYC()
  const { mutateAsync: createKYCSession } = createKYCSessionMutation()
  const executeCreateKYCSession = useCallback(async () => {
    if (!user) return
    const scenario = KYCSessionScenario.Question

    const response = await createKYCSession({
      UUID: user.naturalPerson.UUID ?? '',
      language: i18n.language,
      sessionSenario: scenario,
      sessionType: KYCSessionType.Question,
      // temp fix for user naturalPerson because DateOfBirth is not the same format as intended
      naturalPerson: {
        ...user.naturalPerson,
        DateOfBirth: {
          ...user.naturalPerson.DateOfBirth,
          date: user.naturalPerson.DateOfBirth.date.substring(0, 10)
        }
      }
    })

    setKYCSessionState(response)
  }, [user, i18n.language])

  useEffect(() => {
    if (!KYCSessionState) {
      executeCreateKYCSession()
    }
  }, [])

  const [KYCSessionState, setKYCSessionState] = useState<KYCSession | null>(null)
  return (
    <>
      {!KYCSessionState && (
        <div className="flex flex-1 items-center justify-center mb-4">
          <Loader className="text-gray-500" />
        </div>
      )}
      {KYCSessionState && (
        <KYCSessionIFrame
          className={'h-[80vh]'}
          naturalPersonUUID={user?.naturalPerson.UUID}
          KYCSessionState={KYCSessionState}
          params={{ powered_by: false, show_consent: false, show_home: false, show_final: false }}
          onValidated={onValidated}
        />
      )}
    </>
  )
}
