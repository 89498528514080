import Column from '@/atoms-react/column/Column.react'
import { Dropdown } from '@/atoms-react/dropdown/index.react'
import InputLabel from '@/atoms-react/input/Input.react'
import { Skeletton } from '@/atoms-react/skeletton/index.react'
import { Base } from '@/atoms-react/text/Text.react'
import Flag from '@/atoms/flag/Flag.react'
import { useCityPostalCode } from '@/features/user/address/hooks/use-city-code'
import { useUser } from '@/features/user/hooks/use-user'
import { AddressCreate, CityInfoType } from '@/features/user/types'
import { FAVOURITE_COUNTRY_CODES } from '@/features/utils/constants'
import { useLocale } from '@/features/utils/hooks/use-locale'
import { FormFieldGroup, getRealFieldGroup } from '@/features/utils/types'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { useParams } from 'react-router-dom'

export type AddAddressFormProps = {
  address: AddressCreate
  personNationality?: string[]
  fields?: FormFieldGroup<AddressCreate>
  substep?: string
  countryfirst?: boolean
  onChange: (address: AddressCreate, valid: boolean) => void
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'>

export const FormAddAddress = ({
  address,
  fields = {},
  personNationality,
  onChange: _onChange = () => {},
  ...props
}: AddAddressFormProps) => {
  const { t } = useTranslation(['atoms'])
  const { locale } = useLocale()
  const { useCountries } = useUser()
  const { data: countries, isLoading: isLoadingCountries } = useCountries(locale.language)
  const realFields = getRealFieldGroup(Object.keys(address), fields)
  const { substep } = useParams()
  const countryDomTomId = [10, 110, 151, 100, 197, 155, 191, 195, 244, 102, 167, 103]
  const updateSpecificRule = (address: AddressCreate) => {
    if (realFields.zip) {
      realFields.zip.required = countries?.countries.some(
        (a) => a.id === address.country && a.alpha2 === 'FR'
      )
    }
    if (personNationality && personNationality[0] !== 'FR') realFields.zip.required = false
  }

  const checkValidity = (address: AddressCreate): boolean => {
    updateSpecificRule(address)
    const valid = !Object.keys(realFields).some((key) => {
      const field = realFields[key as keyof AddressCreate]
      return field?.visible && field?.required && !address[key as keyof AddressCreate]
    })
    return valid
  }

  updateSpecificRule(address)

  const onChange = (address: AddressCreate) => {
    const valid = checkValidity(address)
    _onChange(address, valid)
  }

  const [suggestions, setSuggestions] = useState<CityInfoType[]>()
  const [selected, setSelected] = useState<boolean>(false)
  const [locked, setLocked] = useState<boolean>(false)

  const [query, setQuery] = useState<string>()

  if (isLoadingCountries || !countries) return <Skeletton />
  const { getPostalCodeByCity } = useCityPostalCode()
  const { mutateAsync: getCityPostalCode } = getPostalCodeByCity()

  const handleInputChange = async (address: AddressCreate) => {
    if (locked) return
    setSelected(false)
    const value = address.city
    const valid = checkValidity(address)
    address.zip = ''
    _onChange(address, valid)
    setQuery(value)
    if (value.length === 0 || !value) setSuggestions(undefined)
    if (value && value?.length > 0) {
      const data = {
        address: value
      }
      const response = await getCityPostalCode(data)
      const filtredArray = response?.filter(
        (city) => city.nom.toLocaleLowerCase() === value.toLocaleLowerCase()
      )
      setSuggestions(filtredArray.length > 0 ? filtredArray : response)
    }
  }

  const onClickCity = (value: CityInfoType) => {
    const validCity = checkValidity({
      ...address,
      city: value.nom,
      zip: value.departement.code
    } as AddressCreate)
    address.city = value.nom
    address.zip = value.departement.code
    _onChange(address, validCity)
    setSelected(true)
    setLocked(true)
  }
  useEffect(() => {
    if (query?.length === 0 || !query) setSuggestions(undefined)
  }, [query])

  useEffect(() => {
    if (personNationality && personNationality[0] !== 'FR') realFields.zip.required = false
    if (personNationality && personNationality[0] === 'FR') {
      if (!countryDomTomId.includes(address.country as number) && !address.zip) address.zip = '99'
      if (countryDomTomId.includes(address.country as number) && address.zip === '99')
        address.zip = ''
    }
  }, [personNationality, address])

  const onClear = () => {
    setQuery('')
    setLocked(false)
    address.city = ''
    address.zip = ''
    const validCity = checkValidity({
      ...address,
      city: '',
      zip: ''
    } as AddressCreate)
    _onChange(address, validCity)
  }
  return (
    <Column
      className={twMerge('w-full z-10', props.className)}
      {..._.omit(props, 'onChange', 'className')}
    >
      {(realFields?.tag ? !!realFields.tag.visible : true) && (
        <InputLabel
          autoFocus={!realFields.tag?.disabled && props.autoFocus}
          value={address.tag}
          onChange={(value) => onChange({ ...address, tag: value })}
          label={realFields?.tag?.label ?? t('molecules.add_address_form.tag_label')}
          placeholder={realFields?.tag?.placeholder ?? t('molecules.add_address_form.tag_label')}
          required={realFields.tag?.required}
          disabled={realFields.tag?.disabled}
        />
      )}

      {(realFields?.country ? !!realFields?.country.visible : true) && !!props.countryfirst && (
        <CountryInput substep={substep} address={address} fields={fields} onChange={onChange} />
      )}
       {(realFields?.country ? !!realFields?.country.visible : true) && !props.countryfirst && (
        <CountryInput  address={address} fields={fields} onChange={onChange} />
      )}
      <div className="mt-6">
        {countryDomTomId.includes(address.country as number)  ? (
          <>
            {(realFields?.city ? !!realFields?.city.visible : true && !realFields.zip.visible) && (
              <div className="flex flex-col  relative">
                <InputLabel
                  value={
                    address.city && address.zip ? `${address.city} (${address.zip})` : address.city
                  }
                  autoComplete="address-level2"
                  onChange={(value) => handleInputChange({ ...address, city: value })}
                  label={realFields?.city?.label ?? t('molecules.add_address_form.city_line_label')}
                  placeholder={
                    realFields?.city?.placeholder ?? t('molecules.add_address_form.city_line_label')
                  }
                  required={realFields.city?.required}
                  disabled={realFields.city?.disabled}
                />
                {suggestions && suggestions.length > 0 && !selected && (
                  <ul className="overflow-auto	absolute top-20  z-50  mt-2  max-h-40   list-outside hover:list-inside mt-1 bg-white rounded-sm dark:bg-slate-900  hover:border-slate-100 w-full font-semibold  border-2 duration-300 border-slate-50 dark:border-slate-800 text-black dark:text-white dark:hover:bg-slate-900 dark:hover:border-slate-800 focus:bg-white dark:focus:bg-slate-900 focus:border-blue-500 dark:focus:border-blue-500 placeholder:font-semibold placeholder:text-slate-400 dark:placeholder:text-slate-600 ">
                    {suggestions.length > 0 ? (
                      suggestions.slice(0, 20).map((city: CityInfoType) => (
                        <li
                          onClick={() => onClickCity(city)}
                          key={city.code}
                          className="  my-1 cursor-pointer hover:bg-slate-50 dark:bg-slate-900 dark:hover:bg-slate-600 hover:rounded-sm	 px-4 py-4"
                        >
                          {city.nom} ({city.departement.code})
                        </li>
                      ))
                    ) : (
                      <li>{t('molecules.add_address_form.not_found')}</li>
                    )}
                  </ul>
                )}

                {locked && (
                  <XMarkIcon
                    onClick={onClear}
                    className="h-6 w-6 focus:outline-none absolute top-1/2 transform translate-y-1 right-4 text-white-600 hover:text-white focus:pointer"
                    aria-hidden="true"
                  />
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <div className={realFields.zip.visible ? 'flex ' : ''}>
              {(realFields?.city ? !!realFields?.city.visible : true) && (
                <InputLabel
                  value={address.city}
                  autoComplete="address-level2"
                  className={realFields.zip.visible ? 'grow mr-4' : ''}
                  onChange={(value) => onChange({ ...address, city: value })}
                  label={realFields?.city?.label ?? t('molecules.add_address_form.city_line_label')}
                  placeholder={
                    realFields?.city?.placeholder ?? t('molecules.add_address_form.city_line_label')
                  }
                  required={realFields.city?.required}
                  disabled={realFields.city?.disabled}
                />
              )}
              {(realFields?.zip ? !!realFields?.zip.visible : true) && (
                <InputLabel
                  value={address.zip}
                  autoComplete="postal-code"
                  className={'grow'}
                  onChange={(value) => onChange({ ...address, zip: value })}
                  label={realFields?.zip?.label ?? t('molecules.add_address_form.zip_code_label')}
                  placeholder={
                    realFields?.zip?.placeholder ?? t('molecules.add_address_form.zip_code_label')
                  }
                  maxLength={5}
                  required={realFields.zip.required}
                  disabled={realFields.zip?.disabled}
                />
              )}
            </div>
          </>
        )}
      </div>
      {(realFields?.address ? !!realFields?.address.visible : true) && (
        <div className="mt-6">
          <InputLabel
            autoFocus={!realFields.tag?.disabled && props.autoFocus}
            autoComplete="address-line1"
            value={address.address}
            onChange={(value) => onChange({ ...address, address: value })}
            label={realFields?.address?.label ?? t('molecules.add_address_form.address_line_label')}
            placeholder={
              realFields?.address?.placeholder ?? t('molecules.add_address_form.address_line_label')
            }
            required={realFields.address?.required}
            disabled={realFields.address?.disabled}
          />
        </div>
      )}

     
    </Column>
  )
}

export const CountryInput = ({
  address,
  fields = {},
  substep,
  onChange = () => {}
}: Pick<AddAddressFormProps, 'address' | 'fields' | 'onChange' | 'substep'>) => {
  const { t } = useTranslation(['atoms'])

  const { locale } = useLocale()
  const { useCountries } = useUser()
  const { data: countries } = useCountries(locale.language)
  const realFields = getRealFieldGroup(Object.keys(address), fields)

  return (
    <Dropdown
      label={realFields?.country?.label ?? t('molecules.add_address_form.country_label')}
      placeholder={
        realFields?.country?.placeholder ?? t('molecules.add_address_form.country_placeholder')
      }
      autoComplete="country"
      handleAutoComplete={(autoComplete, items, change) => {
        const country = (countries?.countries ?? []).find(
          (country) => country.alpha2.toLowerCase() === autoComplete.toLowerCase()
        )
        const autocompletedValue =
          items.find((item) => item?.key === country?.id.toString()) ?? null

        if (autocompletedValue) change(autocompletedValue)
      }}
      onChange={(value) => {
        onChange(
          {
            ...address,
            city: substep === 'birth' ? '' : address.city,
            country: parseInt(value.key)
          },
          true
        )
      }}
      value={address.country?.toString()}
      className={'mt-6'}
      //value={address.country?.toString()}
      groups={[{ key: 'default' }]}
      items={
        (countries?.countries ?? []).map((country) => ({
          key: country.id.toString(),
          value: country.defaultName,
          icon: <Flag countryCode={country.alpha2} />,
          render: ({ key }) => {
            return (
              <div className={'w-full flex'} key={key}>
                <Flag countryCode={country.alpha2} className="!w-6 !h-6 rounded-sm" />
                <Base className={'ml-2'}>{country.defaultName}</Base>
              </div>
            )
          },
          groupKey: FAVOURITE_COUNTRY_CODES.includes(country.alpha2.toLowerCase())
            ? 'default'
            : undefined
        })) ?? []
      }
      search={true}
      required={realFields.country?.required}
      disabled={realFields.country?.disabled}
    />
  )
}
