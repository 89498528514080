import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { twMerge } from 'tailwind-merge'

ChartJS.register(ArcElement, Tooltip, Legend)

export const DoughnutChart = ({data, labels, config, className}) => {

  /* const colors = ['#627EEA', '#F7931A', '#00AFEB', '#D2B9F2', '#4597FD']
  const backgroundColor = labels.map((label, index) => colors[index % colors.length]) */
  const labelToColor = (label) => {
    const hash = label.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0)
    const hue = hash % 360 // generate a hue between 0 and 360
    return `hsl(${hue}, 50%, 50%)` // generate a color with the calculated hue
  };
  
  const backgroundColor = labels.map((label) => labelToColor(label))

  const dataChart = {
    labels: labels,
    datasets: [
      {
        label: config.labelName,
        data: data,
        backgroundColor: backgroundColor,
        borderColor: backgroundColor,
        borderWidth: 0,
      },
    ],
  }
  
  const optionsChart = {
    responsive: true,
    plugins: {
      legend: {
        display: config?.display?.legend ?? false
      },
      title: {
        display: config?.display?.title ?? false
      },
      datalabels: {
        display: config?.display?.datalabels ?? false
      }
    }
  }

  return (
    <div className={twMerge(
      'flex flex-col space-y-2 md:space-y-0 px-8',
      className
    )}>
      <Doughnut data={dataChart} options={optionsChart} />
    </div>
  )
}
