import { Button } from '@/atoms-react/button/Button.react'
import { Dropdown } from '@/atoms-react/dropdown/index.react'
import InputLabel from '@/atoms-react/input/Input.react'
import { Routes } from '@/features/router'
import { formatNumber } from '@/features/utils/functions'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Icon from '@/atoms-react/Icon/Icon.react'

export const FormConvert = ({config}) => {

  const { t } = useTranslation(['wallet'])
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedCost, setSelectedCost] = useState(0);
  
  useEffect(() => {
    if (config.data && config.data.length > 0) {
      setSelectedValue(config.data[0].symbol);
      setSelectedCost(config.data[0].current_price);
    }
  }, [config.data]);

  const kypayAmount = t('wallet.convert.form.kypay_current') + ' '+ formatNumber(config.kypay.amount / 100) + config.unit
  
  return (
    <>
      <div className='flex flex-col w-full'>
        <div className={'flex flex-row space-y-0 w-full items-end'}>
          <InputLabel
            className={'w-full rounded-e-none'}
            label={t('wallet.convert.form.kpay_amount')}
          />
          <Dropdown
            value={selectedValue}
            onChange={(e) => {
              setSelectedValue(e.key)
              setSelectedCost(e.amount)
            }}
            className={'w-full'}
            dropdownClass={'dark:bg-gray-100 dark:text-slate-300 dark:hover:bg-gray-100 dark:border-gray-100 rounded-sm rounded-s-none'}
            items={
              config.data?.map((item) => ({
                value: item.name,
                key: item.symbol.toUpperCase(),
                amount: item.current_price,
                icon: <Icon path={item.image} />
              })) ?? []
            }
          />
        </div>
        <div className={'text-end py-4'}>
          <Link to={Routes.wallet_dashboard} className="underline underline-offset-1">
            {t('wallet.convert.form.add_euro_kypay')}
          </Link>
        </div>
        <div className={'flex flex-row space-y-0 w-full items-end'}>
          <InputLabel
            className={'w-full rounded-e-none'}
            label={t('wallet.convert.form.convert_amount')}
          />
          <Dropdown
            value={selectedValue}
            onChange={(e) => {
              setSelectedValue(e.key)
              setSelectedCost(e.amount)
            }}
            className={'w-full'}
            dropdownClass={'dark:bg-gray-100 dark:text-slate-300 dark:hover:bg-gray-100 dark:border-gray-100 rounded-sm rounded-s-none'}
            items={
              config.data?.map((item) => ({
                value: item.name,
                key: item.symbol.toUpperCase(),
                amount: item.current_price,
                icon: <Icon path={item.image} />
              })) ?? []
            }
          />
        </div>
        <div className={'text-end py-4'}>
          {t('wallet.convert.form.value_current')} {selectedValue} : <span className='text-blue-500'>{formatNumber(selectedCost)} {config.unit}</span>
        </div>
        <div className={'flex flex-row space-y-0 w-full items-end'}>
          <Dropdown
            label={t('wallet.convert.form.order_type')}
            value='store'
            className={'w-full'}
            dropdownClass={'dark:bg-gray-100 dark:text-slate-300 dark:hover:bg-gray-100 dark:border-gray-100 rounded-sm rounded-e-none'}
            items={[
              {
                value: t('wallet.convert.form.store_type'),
                key: 'store'
              }
            ]}
          />
          <InputLabel
            className={'w-full rounded-s-none'}
            value={formatNumber(selectedCost) + ' ' + config.unit}
            disabled
          />
        </div>

        <div className={'flex flex-row space-y-0 w-full items-center'}>
          <div className='items-start flex flex-col w-full'>
            <div className={'text-start pt-12'}>
              {t('wallet.convert.form.commission')} <span className='text-blue-500'>1%</span>
            </div>
            <div className={'text-start py-4'}>
              {t('wallet.convert.form.final_rate')} {selectedValue} = <span className='text-blue-500'>{formatNumber(selectedCost)} {config.unit}</span>
            </div>
            <Link to={Routes.wallet_dashboard} className="text-blue-500 underline underline-offset-1">
              {t('wallet.convert.form.livraison')}
            </Link>
          </div>
          <div className='justify-end'>
            <Button
              theme="primary"
              size="lg"
              className='flex'
            >
              {t('wallet.convert.form.button')}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
