import { CheckCircle } from '@/atoms-react/check-circle/check-circle.react'
import Column from '@/atoms-react/column/Column.react'
import Image from '@/atoms-react/image/Image.react'
import { Base, BigTitle } from '@/atoms-react/text/Text.react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LoginSpaceshipAnimation } from '../../../../../components/animations/login-spaceship/index.react'
import { useOrigin } from '@/features/flow/hooks/use-origin'
import { FromURL } from '@/features/flow/types'

export const OlkyIntroduction = ({ mode: _mode }: { mode: 'short' | 'long' }) => {
  const { t } = useTranslation(['pass'])
  const timeouts = useRef<any>(0)
  const [mode, setMode] = useState<'short' | 'long'>(_mode)
  const [visible, setVisible] = useState(true)
  const origin = useOrigin()
  // Fade in fade out
  useEffect(() => {
    if (mode === _mode) return
    setVisible(false)
    clearTimeout(timeouts.current)
    timeouts.current = setTimeout(() => {
      setMode(_mode)
      clearTimeout(timeouts.current)
      timeouts.current = setTimeout(() => {
        setVisible(true)
      }, 200)
    }, 200)
  }, [_mode])

  const getSteps = () => {
    switch (origin) {
      case FromURL.OlkyPayNP:
        return [
          t('flow.id_control.olky_intro.steps_olkyPayNP.1'),
          t('flow.id_control.olky_intro.steps_olkyPayNP.2'),
          t('flow.id_control.olky_intro.steps_olkyPayNP.3')
        ]
      case FromURL.OlkyPayLE:
        return [
          t('flow.id_control.olky_intro.steps_olkyPayLE.4'),
          t('flow.id_control.olky_intro.steps_olkyPayLE.2'),
          t('flow.id_control.olky_intro.steps_olkyPayLE.1'),
          t('flow.id_control.olky_intro.steps_olkyPayLE.3')
        ]
      case FromURL.Wallet:
        return [
          t('flow.id_control.olky_intro.steps_wallet.1'),
          t('flow.id_control.olky_intro.steps_wallet.2'),
          t('flow.id_control.olky_intro.steps_wallet.3')
        ]
      default:
        return [
          t('flow.id_control.olky_intro.steps.1'),
          t('flow.id_control.olky_intro.steps.2'),
          t('flow.id_control.olky_intro.steps.3'),
          t('flow.id_control.olky_intro.steps.4')
        ]

    }
  }

  const steps = getSteps()

  const getBigTitle = () => {
    switch (origin) {
      case FromURL.OlkyPayNP:
        return t('flow.id_control.olky_intro.title.olkyPayNP')
      case FromURL.OlkyPayLE:
        return t('flow.id_control.olky_intro.title.olkyPayLE')
      case FromURL.Wallet:
        return t('flow.id_control.olky_intro.title.olkyWallet')
      default:
        return t('flow.id_control.olky_intro.title.default')
    }
  }

  const getDescription = () => {
    switch (origin) {
      case FromURL.OlkyPayNP:
        return t('flow.id_control.olky_intro.desc.olkyPayNP')
      case FromURL.OlkyPayLE:
        return t('flow.id_control.olky_intro.desc.olkyPayLE')
      case FromURL.Wallet:
        return t('flow.id_control.olky_intro.desc.olkyWallet')
      default:
        return t('flow.id_control.olky_intro.desc.default')
    }
  }
  const getLogo = () => {
    switch (origin) {
      case FromURL.OlkyPayNP:
        return '/images/olkypass/olkypay-logo.svg'
      case FromURL.OlkyPayLE:
        return '/images/olkypass/olkypay-logo.svg'
      case FromURL.Wallet:
        return '/images/olkypass/olkywallet-logo.svg'
      default:
        return '/images/olkypass/logo-olky-pass.svg'
    }
  }
  const getColorCheck = () => {
    switch (origin) {
      case FromURL.OlkyPayNP:
        return 'bg-blue-500'
      case FromURL.OlkyPayLE:
        return 'bg-blue-500'
      case FromURL.Wallet:
        return 'bg-orange-500'
      default:
        return 'bg-yellow-500'
    }
  }

  return (
    <div
      className={
        'relative transition-all flex flex-col items-center ' +
        (visible ? 'opacity-100' : 'opacity-0')
      }
    >
      {mode !== 'short' && (
        <>
          <div className="w-full max-w-2xl z-10 xl:px-8">
            <Image src={getLogo()} className={'w-40 mb-6 inline-block'} alt="Olky Logo" />
            <BigTitle className={'text-white pb-4'}>{getBigTitle()}</BigTitle>
            <Base className={'text-white pb-8 opacity-95 hidden lg:block'}>
              {getDescription()}
            </Base>
            <Column className="hidden lg:block">
              {steps.map((step, index) => (
                <div key={index} className={'pb-6 flex items-center'}>
                  <CheckCircle className={getColorCheck()} />
                  <Base className={'text-white w-full break-normal font-semibold'}>{step}</Base>
                </div>
              ))}
            </Column>
            <div className={'h-8 mb-6 hidden lg:block'} />
          </div>

          <div
            className={'z-[-1] ' + 'hidden lg:absolute top-0 left-0 right-0 bottom-0 opacity-50 '}
          >
            <Image
              alt="Olky Planet Blue"
              src="/images/olkypass/olky-planet-blue.png"
              className={`z-[-1] absolute -left-8 -top-32 ` + 'lg:w-60 lg:h-60'}
            />
            <Image
              alt="Olky Planet Yellow"
              src="/images/olkypass/olky-planet-yellow.png"
              className={`z-[-1] absolute -right-16 -top-32 ` + 'w-60 h-60'}
            />
            <Image
              alt="Olky Planet Orange"
              src="/images/olkypass/olky-planet-orange.png"
              className={`z-[-1] absolute -bottom-32 right-0 ` + 'w-60 h-60'}
            />
          </div>
        </>
      )}

      {mode === 'short' &&
        origin !== FromURL.Wallet &&
        origin !== FromURL.OlkyPayLE &&
        origin !== FromURL.OlkyPayNP && (
          <LoginSpaceshipAnimation className="z-10 h-dvh flex flex-col items-center justify-center" />
        )}
    </div>
  )
}
