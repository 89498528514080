import { useUser } from '@/features/user/hooks/use-user'
import { useCompanyContext } from './use-company-context'
import { KYCStatusType } from '@/features/kyc/types'
import { useKYC } from '@/features/kyc/hooks/use-kyc'

export const useKYCContext = () => {
  const { companyDetail } = useCompanyContext()
  const { useUserProfile } = useUser()
  const { data: user, isPending: isUserPending, refetch: refetchUserProfile } = useUserProfile()

  const { KYCStateQuery } = useKYC()
  const { data: kycSessionStatus, isPending, refetch: refetchKycSessionStatus } = KYCStateQuery(
    companyDetail
      ? companyDetail?.data?.company?.identity_uuid ?? null
      : user?.naturalPerson.UUID ?? null
  )

  const refetchKYCContext = async () => {
    await Promise.all([refetchUserProfile(), refetchKycSessionStatus()])
  }
  const addressVerified = false
  // const kycSessionStatus = companyDetail
  //   ? companyDetail.data?.olkyPassLevel ?? null
  //   : user?.olkyPassLevel ?? null
  const algoregKYCStatus = user?.olkyPassLevel.algoreg_kyc_status;
  const kycLevel = kycSessionStatus?.level || 0
  let status: KYCStatusType = KYCStatusType.DECLARATIVE

  if (isPending || isUserPending) {
    status = KYCStatusType.LOADING
  } else {
    if (companyDetail) {
      status = kycLevel === 100 ? KYCStatusType.VERIFIED : KYCStatusType.NEUTRAL
    } else {
      if (algoregKYCStatus === "POSITIVE") {
        status = addressVerified ? KYCStatusType.AUTHENTICATED : KYCStatusType.VERIFIED
      } 
      else if ((algoregKYCStatus === "NEUTRAL") || (algoregKYCStatus === "PENDING")) status = KYCStatusType.NEUTRAL
      else status = KYCStatusType.DECLARATIVE
    }
  }
  return { kycSessionStatus, status, refetchKYCContext, algoregKYCStatus }
}
