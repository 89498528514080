import { A } from '@/atoms-react/a/A.react'
import { Dropdown } from '@/atoms-react/dropdown/index.react'
import InputLabel from '@/atoms-react/input/Input.react'
import { Base, Label } from '@/atoms-react/text/Text.react'
import Flag from '@/atoms/flag/Flag.react'
import { useKYCContext } from '@/features/auth/hooks/use-kyc-context'
import { KYCStatusType } from '@/features/kyc/types'
import { useUser } from '@/features/user/hooks/use-user'
import { convert_naturalPerson_DTO } from '@/features/user/converters'
import { useLocale } from '@/features/utils/hooks/use-locale'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { NaturalPersonDTO } from '@/features/user/types'
import Loader from '@/atoms-react/loader/Loader.react'
import toast from 'react-hot-toast'
import { DateTime } from 'luxon'
import { useNativeInputs } from '@/features/utils/is-mobile'
import { Alert, AlertType } from '@/atoms-react/alert/index.react.tsx'
import { getFullName } from '@/features/utils/functions.ts'
import { ModalUpdateInfoHelper } from '@/app/olkypass/views/identity/components/modal-update-info-helper/index.react.tsx'
import { Button } from '@/atoms-react/button/Button.react.tsx'
import { PencilIcon } from '@heroicons/react/24/solid'
import { Themes } from '@/types/theme.ts'
type IdentityNPContentProps = React.ComponentPropsWithoutRef<'div'>

const cspWithoutActivity = { CHÔMEUR: 14, INACTIF: 18, MILITAIRE: 13, ETUDIANT: 15 }
const isIdentityValid = (data: NaturalPersonDTO) => {
  if (!Object.values(cspWithoutActivity).includes(data.CSPCode))
    return (
      data.LastName &&
      data.FirstName &&
      data.CountryOfCitizenship &&
      data.countryOfBirth &&
      data.Birthplace &&
      data.DateOfBirth.date &&
      data.CSPCode &&
      data.ActivityCode &&
      data.StateOfBirth
    )
  else
    return (
      data.LastName &&
      data.FirstName &&
      data.CountryOfCitizenship &&
      data.countryOfBirth &&
      data.Birthplace &&
      data.DateOfBirth.date &&
      data.ActivityCode &&
      data.StateOfBirth
    )
}

const isBirthDateValid = (date: string) => {
  if (!date) {
    return false
  }
  const min = DateTime.now().minus({ years: 18 })
  if (min < DateTime.fromFormat(date, 'yyyy-MM-dd')) {
    return false
  }
  return true
}

export const IdentityNPContent = ({ ...props }: IdentityNPContentProps) => {
  const { t } = useTranslation(['pass'])

  const { locale } = useLocale()
  const { useUserProfile, useCountries, useCSP, useActivities, useEditProfile } = useUser()
  const { mutateAsync: editProfile, isPending: isEditing } = useEditProfile()
  const { data: user, refetch: refetchUser, isRefetching: isLoadingUser } = useUserProfile()
  const { data: countriesData } = useCountries(locale.language)
  const { data: CSPCodes } = useCSP(locale.language)
  const { data: activities } = useActivities(locale.language)
  const { status } = useKYCContext()
  const [userForm, setUserForm] = useState<NaturalPersonDTO & { isDirty: boolean }>()
  const [modalInfoHelper, setModalInfoHelper] = useState<boolean>(false)
  const isMobile = useNativeInputs()

  useEffect(() => {
    if (user) {
      setUserForm({ ...convert_naturalPerson_DTO(user.naturalPerson), isDirty: false })
    }
  }, [user])

  const onInputChange = (person: NaturalPersonDTO) => {
    setUserForm({ ...person, isDirty: true })
  }

  const onSave = async () => {
    if (userForm && !isLoadingUser && !isEditing) {
      if (!isIdentityValid(userForm)) {
        window.dispatchEvent(new Event('checkRequiredFields'))
        toast.error(t('input.form_incomplete'))
        return
      }
      if (!isBirthDateValid(userForm.DateOfBirth?.date)) {
        window.dispatchEvent(new Event('setRequired-birth_date'))
        toast.error(t('flow.id_control.error.must_be_18_plus'))
        return
      }
      await editProfile(userForm)
      const { data: newUser } = await refetchUser()
      if (newUser) {
        setUserForm({ ...convert_naturalPerson_DTO(newUser.naturalPerson), isDirty: false })
      }
      toast.success(t('olkypass.accountPage.notifications.success.profile.update'))
      if (newUser?.naturalPerson.UUID) {
        const cachedFreshUser = {
          email: newUser?.naturalPerson?.AliasEmail?.[0]?.Mail || '',
          name: getFullName(newUser?.naturalPerson),
          avatar: ''
        }
        localStorage.setItem('user', JSON.stringify(cachedFreshUser))
      }
    }
  }

  const labelInputDivClassName = 'md:flex items-center md:space-x-6'

  const labelClassName = 'flex w-40 min-w-40 mb-2 md:mb-0'
  const inputClassName = 'flex grow overflow-auto'

  const disabled = status !== KYCStatusType.DECLARATIVE
  const onModalInfoDisplay = () => {
    setModalInfoHelper(true)
  }
  return (
    <div className="2xl:w-3/4 md:mb-6 2xl:mb-0">
      {/*Personal Infos*/}
      <div
        className={twMerge('md:px-6 md:py-4 md:pb-6', props.className)}
        {..._.omit(props, 'className')}
      >
        {userForm && (
          <>
            <div className="flex justify-between mb-5">
              <Base className="text-slate-300 hidden md:block">
                {t('olkypass.accountPage.basicInfo.tabs.informations')}
              </Base>
              {(isLoadingUser || isEditing) && <Loader />}
              {userForm.isDirty && !(isLoadingUser || isEditing) && (
                <A onClick={onSave}>{t('olkypass.general.save')}</A>
              )}
            </div>
            {/* General Infos */}
            {!user?.naturalPerson.DateOfBirth && (
              <Alert
                type={AlertType.Danger}
                title={t('olkypass.mainPage.olkypass.fillIdentityData')}
              />
            )}
            <div className="border-b-2 border-slate-50 md:pt-9 pb-8 space-y-6">
              {isMobile ? (
                <>
                  <div className={labelInputDivClassName}>
                    <Label className={labelClassName}>
                      {t('olkypass.accountPage.basicInfo.input.lastName')}
                    </Label>
                    <div className={inputClassName}>
                      <InputLabel
                        data-tooltip={t('olkypass.accountPage.basicInfo.input.lastName')}
                        placeholder={t('olkypass.accountPage.basicInfo.input.lastName')}
                        required={true}
                        onChange={(LastName) => onInputChange({ ...userForm, LastName })}
                        value={userForm.LastName}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <div className={labelInputDivClassName}>
                    <Label className={labelClassName}>
                      {t('olkypass.accountPage.basicInfo.input.firstName')}
                    </Label>
                    <div className={inputClassName}>
                      <InputLabel
                        data-tooltip={t('olkypass.accountPage.basicInfo.input.firstName')}
                        placeholder={t('olkypass.accountPage.basicInfo.input.firstName')}
                        required={true}
                        onChange={(FirstName) => onInputChange({ ...userForm, FirstName })}
                        value={userForm.FirstName}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className={labelInputDivClassName}>
                  <Label className={labelClassName}>
                    {t('olkypass.accountPage.basicInfo.input.lastName')} {' & '}
                    {t('olkypass.accountPage.basicInfo.input.firstName')}
                  </Label>
                  <div className={twMerge('space-x-3', inputClassName)}>
                    <InputLabel
                      data-tooltip={t('olkypass.accountPage.basicInfo.input.lastName')}
                      placeholder={t('olkypass.accountPage.basicInfo.input.lastName')}
                      required={true}
                      onChange={(LastName) => onInputChange({ ...userForm, LastName })}
                      value={userForm.LastName}
                      disabled={disabled}
                    />
                    <InputLabel
                      data-tooltip={t('olkypass.accountPage.basicInfo.input.firstName')}
                      placeholder={t('olkypass.accountPage.basicInfo.input.firstName')}
                      required={true}
                      onChange={(FirstName) => onInputChange({ ...userForm, FirstName })}
                      value={userForm.FirstName}
                      disabled={disabled}
                    />
                  </div>
                </div>
              )}
              <div className={labelInputDivClassName}>
                <Label className={labelClassName}>
                  {t('olkypass.accountPage.basicInfo.autocomplete.countryOfCitizenship')}
                </Label>
                <div className={inputClassName}>
                  <Dropdown
                    initialValue={userForm.CountryOfCitizenship?.toString()}
                    onChange={(item) =>
                      onInputChange({ ...userForm, CountryOfCitizenship: item.key ?? '' })
                    }
                    required={true}
                    className="w-full"
                    disabled={disabled}
                    search={true}
                    items={
                      countriesData?.countries.map((country) => ({
                        key: country.id.toString(),
                        value: country.defaultName,
                        icon: <Flag countryCode={country.alpha2.toLowerCase()} />
                      })) ?? []
                    }
                  />
                </div>
              </div>
            </div>

            {/* Birth infos */}
            <div className="border-b-2 border-slate-50 pt-7 md:pt-8 pb-8 space-y-6">
              <div className={labelInputDivClassName}>
                <Label className={labelClassName}>
                  {t('olkypass.accountPage.basicInfo.input.birthDate')}
                </Label>
                <div className={inputClassName}>
                  <InputLabel
                    name="birth_date"
                    max={DateTime.now().minus({ years: 18 }).toFormat('yyyy-MM-dd')}
                    required={true}
                    type="date"
                    value={userForm.DateOfBirth?.date?.substring(0, 10)}
                    onChange={(date: string) =>
                      onInputChange({ ...userForm, DateOfBirth: { ...userForm.DateOfBirth, date } })
                    }
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className={labelInputDivClassName}>
                <Label className={labelClassName}>
                  {t('olkypass.accountPage.basicInfo.input.stateOfBirth')}
                </Label>
                <div className={inputClassName}>
                  <InputLabel
                    required={true}
                    value={userForm.StateOfBirth}
                    onChange={(StateOfBirth) => onInputChange({ ...userForm, StateOfBirth })}
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className={labelInputDivClassName}>
                <Label className={labelClassName}>
                  {t('olkypass.accountPage.basicInfo.autocomplete.birthPlace')}
                </Label>
                <div className={inputClassName}>
                  <Dropdown
                    initialValue={userForm.countryOfBirth?.toString()}
                    onChange={(item) =>
                      onInputChange({ ...userForm, countryOfBirth: item.key ?? '' })
                    }
                    required={true}
                    className="w-full"
                    disabled={disabled}
                    search={true}
                    items={
                      (countriesData?.countries ?? []).map((country) => ({
                        key: country.id.toString(),
                        value: country.defaultName,
                        icon: <Flag countryCode={country.alpha2.toLowerCase()} />
                      })) ?? []
                    }
                  />
                </div>
              </div>

              <div className={twMerge('items-end flex flex-col', disabled ? '' : 'hidden')}>
                <Button
                  className="shrink-0 w-max"
                  onClick={onModalInfoDisplay}
                  icon={PencilIcon}
                  theme={Themes.white}
                >
                  {t('olkypass.accountPage.basicInfo.button.update_helper')}
                </Button>
              </div>
            </div>

            {/* Activity */}
            <div className="pt-7 md:pt-8 space-y-6 md:mb-2">
              <div className={labelInputDivClassName}>
                <Label className={labelClassName}>
                  {t('olkypass.accountPage.basicInfo.autocomplete.cspCode')}
                </Label>
                <div className={inputClassName}>
                  <Dropdown
                    initialValue={userForm?.CSPCode?.toString()}
                    onChange={(item) =>
                      onInputChange({ ...userForm, CSPCode: item.key ? parseInt(item.key) : -1 })
                    }
                    required={true}
                    className="w-full"
                    search={true}
                    items={
                      (CSPCodes?.cspCodes ?? []).map((cspCode) => ({
                        key: cspCode.Code.toString(),
                        value: cspCode.defaultName
                      })) ?? []
                    }
                  />
                </div>
              </div>
              {!Object.values(cspWithoutActivity).includes(userForm.CSPCode) && (
                <div className={labelInputDivClassName}>
                  <Label className={labelClassName}>
                    {t('olkypass.accountPage.basicInfo.autocomplete.activityCode')}
                  </Label>
                  <div className={inputClassName}>
                    <Dropdown
                      initialValue={userForm?.ActivityCode?.toString()}
                      onChange={(item) =>
                        onInputChange({
                          ...userForm,
                          ActivityCode: item.key ? parseInt(item.key) : -1
                        })
                      }
                      className="w-full"
                      search={true}
                      items={
                        (activities?.activities ?? []).map((activity) => ({
                          key: activity.Code.toString(),
                          value: activity.defaultName
                        })) ?? []
                      }
                    />
                  </div>
                </div>
              )}
              <div className={labelInputDivClassName}>
                <Label className={labelClassName}>
                  {t('olkypass.accountPage.basicInfo.input.nif')}
                </Label>
                <div className={inputClassName}>
                  <InputLabel
                    value={userForm.NIF ?? undefined}
                    onChange={(NIF) => onInputChange({ ...userForm, NIF })}
                  />
                </div>
              </div>
              <div className="mt:5 md:hidden">
                {(isLoadingUser || isEditing) && <Loader />}
                {userForm.isDirty && !(isLoadingUser || isEditing) && (
                  <A onClick={onSave}>{t('olkypass.general.save')}</A>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <ModalUpdateInfoHelper
        open={modalInfoHelper}
        onClose={() => {
          setModalInfoHelper(false)
        }}
      ></ModalUpdateInfoHelper>
    </div>
  )
}
