import { fetchServer } from '@/features/utils/fetch'
import {
  AccountReason,
  CheckIbanBic,
  CreateQuizResponse,
  PaymentUsage,
  QuizResponses,
  SourceIncome
} from '@/features/user/quiz/types'
import { CreateBankAccountResponse } from '@/features/user/bank-account/types.ts'

export class QuizAPIClient {
  static async getSourcesIncome(locale: string): Promise<SourceIncome[]> {
    const response = await fetchServer(`public/source_income/${locale}`, {
      method: 'GET',
      reloadOn401: false,
      mainRoot: false
    })

    if (!response.ok) throw new Error('Error')

    const res = await response.json()
    return res
  }
  static async getAccountReasons(locale: string): Promise<AccountReason[]> {
    const response = await fetchServer(`public/account_reason/${locale}`, {
      method: 'GET',
      reloadOn401: false,
      mainRoot: false
    })

    if (!response.ok) throw new Error('Error')

    const res = await response.json()
    return res
  }

  static async getPaymentsUsage(locale: string): Promise<PaymentUsage[]> {
    const response = await fetchServer(`public/payment_usage/${locale}`, {
      method: 'GET',
      reloadOn401: false,
      mainRoot: false
    })

    if (!response.ok) throw new Error('Error')

    const res = await response.json()
    return res
  }
  static async create(quizResponses: QuizResponses): Promise<CreateQuizResponse> {
    const response = await fetchServer('/user/account_quiz/create', {
      method: 'POST',
      reloadOn401: false,
      mainRoot: false,
      body: quizResponses
    })

    if (!response.ok) throw new Error()
    const json: { success: boolean } = await response.json()

    return json
  }

  static async checkIban(iban: string): Promise<CheckIbanBic> {
    const response = await fetchServer('/user/account_quiz/check_iban', {
      method: 'POST',
      reloadOn401: false,
      mainRoot: false,
      body: { iban: iban }
    })

    if (!response.ok) throw new Error()
    const json: { success: boolean; bic?: string } = await response.json()

    return json
  }
  static async isValidatedIban(iban: string): Promise<CheckIbanBic> {
    const response = await fetchServer('/user/account_quiz/is_validated_iban', {
      method: 'POST',
      reloadOn401: false,
      mainRoot: false,
      body: { iban: iban }
    })

    if (!response.ok) throw new Error()
    const json: { success: boolean; valid?: boolean } = await response.json()

    return json
  }
}
