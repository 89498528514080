import { Button } from '@/atoms-react/button/Button.react'
import { Modal, ModalContent, ModalProps } from '@/atoms-react/modal/index.react'
import { ChevronRightIcon, ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/20/solid'
import { useTranslation } from 'react-i18next'
import { Routes } from '@/features/router.ts'
import { useNavigate } from 'react-router-dom'
import { Themes } from '@/types/theme.ts'
import { Alert, AlertType } from '@/atoms-react/alert/index.react.tsx'
import React from 'react'

export const ModalUpdateInfoHelper = ({ onValidated = () => {}, ...props }) => {
  const { t } = useTranslation(['pass'], { useSuspense: false })
  const navigate = useNavigate()
  return (
    <Modal {...props} maxWidthClassName="max-w-lg">
      <ModalContent title={t('olkypass.accountPage.basicInfo.helper.header')}>
        <div className={'opacity-1 flex flex-col'}>
          <div className={'w-full my-6'}>
            <Alert type={AlertType.Warning} title="">
              <span className="text-sm font-medium">
                {t('olkypass.accountPage.basicInfo.helper.text')}
              </span>
            </Alert>
          </div>
          <Button
            className="mt-2 shrink-0 w-full"
            theme={Themes.white}
            onClick={() => navigate(Routes.support)}
            icon={ChatBubbleOvalLeftEllipsisIcon}
          >
            {t('olkypass.accountPage.basicInfo.helper.button')}
          </Button>
        </div>
      </ModalContent>
    </Modal>
  )
}
