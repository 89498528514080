/** Do not change directly this file, instead create a environment.ts from environment.ts.dist */

//import { environment as overrides } from './environment.ts'
import { isDevelopment, environment as overrides } from './environment.ts'
import environmentServerSide from './environmentServerSide.ts'

type ConfigType = {
  APP_VERSION: string
  OLKY_MAIN_LINK: string
  OLKY_SUPPORT_LINK: string
  OLKY_PRIVACY_POLICY_LINK: string
  OLKY_HELP_LINK: string
  OLKY_ABOUT_LINK: string
  OLKY_LEGAL_LINK: string
  OLKY_CGU_CARD_BUSINESS_FR: string
  OLKY_CGU_CARD_BUSINESS_EN: string
  OLKYPASS_CGU_FR: string
  OLKYPASS_CGU_EN: string
  APP_ROOT_API: string
  API_CHAT_ALGOREG: string
  API_VID_ALGOREG: string
  RECAPTCHA_PUBLIC_KEY: string
  OLKYPASS_MODE: boolean //Probably deprecated now
  SITE_NETCHAIN_ID: string
  POLYGON_NODE: string[]
  BLOCK_EXPLORER: string
  CHAIN_NAME: string
  TOKEN_ADDRESS: string
  TOKEN_SYMBOL: string
  TOKEN_DECIMALS: number
  TOKEN_IMAGE: string
  KYCOIN_BUY_EXTERNAL_PAGE: string
  URL_GOV_FR :string
  OLKY_BANKING_URL: string
}

const config = {
  APP_VERSION: '1.1.3',
  OLKY_MAIN_LINK: 'https://www.olky.eu/',
  OLKY_SUPPORT_LINK: '/universe/support/',
  OLKY_PRIVACY_POLICY_LINK: '/cgu/privacy_policy',
  OLKY_HELP_LINK: '/universe/faq',
  OLKY_ABOUT_LINK: '/universe/about',
  OLKY_LEGAL_LINK: '/cgu/legal',
  OLKY_CGU_CARD_BUSINESS_FR: '/files/pay/cgu-olkypay-carte-business-fr.pdf?1.0.1',
  OLKY_CGU_CARD_BUSINESS_EN: '/files/pay/cgu-olkypay-carte-business-en.pdf?1.0.1',
  OLKYPASS_CGU_FR: 'https://www.olky.eu/files/pass/cgu-olkypass-fr.pdf',
  OLKYPASS_CGU_EN: 'https://www.olky.eu/files/pass/cgu-olkypass-en.pdf',
  APP_ROOT_API: 'https://apidev.olkypay.com',
  API_VID_ALGOREG: 'https://vid.olky-dev.algoreg.com',
  RECAPTCHA_PUBLIC_KEY: '6LeXEJ0lAAAAACBgL1wKFXUEUg6SbVAFpIIFVkWq',
  URL_GOV_FR: 'https://geo.api.gouv.fr/',
  API_CHAT_ALGOREG: 'https://chat.olky-dev.algoreg.com/',
  OLKYPASS_MODE: false, //Probably deprecated now,
  ...(isDevelopment
    ? {
        SITE_NETCHAIN_ID: '0x13882',
        POLYGON_NODE: [
          'https://rpc.ankr.com/polygon_amoy',
          'https://rpc-amoy.polygon.technology',
          'https://polygon-amoy.drpc.org',
          'https://polygon-amoy-bor-rpc.publicnode.com'
        ],
        BLOCK_EXPLORER: 'https://amoy.polygonscan.com/',
        CHAIN_NAME: 'Polygon Testnet',
        TOKEN_ADDRESS: '0x5E45F8aF7e3526214d634e055E515d5fff2bb75f',
        TOKEN_SYMBOL: 'KYPAY',
        TOKEN_DECIMALS: 18,
        TOKEN_IMAGE: 'https://www.olky.eu/images/kypay/kyPay-logo-light.svg'
      }
    : {
        SITE_NETCHAIN_ID: '0x89',
        POLYGON_NODE: [
          'https://polygon-rpc.com/',
          'https://polygon-bor-rpc.publicnode.com',
          'https://polygon.drpc.org',
          'https://polygon-mainnet.public.blastapi.io',
          'https://rpc.ankr.com/polygon'
        ],
        BLOCK_EXPLORER: 'https://polygonscan.com/',
        CHAIN_NAME: 'Polygon Mainnet',
        TOKEN_ADDRESS: '0xE833fEBb65da5358E898C10b398A21a4546f3825',
        TOKEN_SYMBOL: 'KYPAY',
        TOKEN_DECIMALS: 18,
        TOKEN_IMAGE: 'https://www.olky.eu/images/kypay/kyPay-logo-light.svg'
      }),
  OLKY_BANKING_URL: isDevelopment ? 'https://my.uat.olkypay.com' : 'https://my.olky.eu',
  // @TODO Use Infura instead for more stability
  ...((overrides as Partial<ConfigType>) ?? {}),
  ...(environmentServerSide as Partial<ConfigType>)
} as ConfigType
export default config
