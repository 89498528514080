import { QuizStepProps } from '@/features/user/quiz/types.ts'
import { useTranslation } from 'react-i18next'
import { useQuiz } from '@/features/user/quiz/hooks/use-quiz.ts'
import { HeaderIbanModal } from '@/molecules/modal-iban-account/header.react.tsx'
import { Base, SectionTitleSmall } from '@/atoms-react/text/Text.react.tsx'
import { RadioCard } from '@/atoms-react/radio-card/index.react.tsx'
import DotsNavigation from '@/atoms-react/dots-navigation/DotsNavigation.react.tsx'
import { Button } from '@/atoms-react/button/Button.react.tsx'
import { Themes } from '@/types/theme.ts'
import React from 'react'

export const MonthlyIncomeStep = ({
  setStep,
  quizResponses,
  setQuizResponses,
  stepSettings
}: QuizStepProps) => {
  const { t } = useTranslation(['pass'])
  const monthlyIncomes = [
    {
      trans_key: 'less_1500',
      minimum: 0,
      maximum: 1500
    },
    {
      trans_key: 'between_1500_and_5000',
      minimum: 1500,
      maximum: 5000
    },
    {
      trans_key: 'between_5000_and_10000',
      minimum: 5000,
      maximum: 10000
    },
    {
      trans_key: 'more_10000',
      minimum: 10000,
      maximum: null
    }
  ]
  const handleRadioBoxChange = (checkedMonthlyIncomesState) =>
    setQuizResponses((prev) => ({
      ...prev,
      monthly_income: { ...checkedMonthlyIncomesState }
    }))

  const isQuizAnswered = () => {
    return quizResponses?.monthly_income && Object.keys(quizResponses?.monthly_income).length !== 0
  }
  return (
    <>
      <SectionTitleSmall>
        {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.steps.monthly_incomes.question')}
      </SectionTitleSmall>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 my-4 mt-4">
        {monthlyIncomes.map((m) => (
          <RadioCard
            active={
              quizResponses?.monthly_income &&
              quizResponses?.monthly_income?.trans_key === m.trans_key
            }
            key={m.trans_key}
            onClick={() => handleRadioBoxChange(m)}
          >
            <Base>
              {t(
                'olkypass.mainPage.olkypass.modalOpenOlkyPay.steps.monthly_incomes.responses.' +
                  m.trans_key
              )}
            </Base>
          </RadioCard>
        ))}
      </div>
      <div className="flex flex-col items-center mt-auto mb-4">
        <DotsNavigation total={7} current={stepSettings.current} className="mb-4" />
        <div className="w-full flex flex-col-reverse gap-y-2 md:flex-row md:gap-x-2">
          <Button
            theme={Themes.white}
            onClick={() => {
              setStep(stepSettings.previous)
            }}
          >
            {t('olkypass.general.go_back')}
          </Button>

          <Button
            disabled={!isQuizAnswered()}
            onClick={() => {
              setStep(stepSettings.next)
            }}
          >
            {t('olkypass.general.continue')}
          </Button>
        </div>
      </div>
    </>
  )
}
