import React, { useState } from 'react'
import Column from '@/atoms-react/column/Column.react'
import { LayoutTitle } from '../../../_layout/index.react'
import { twMerge } from 'tailwind-merge'
import { Base, Info, SectionTitle } from '@/atoms-react/text/Text.react'
import { EmailCard } from '@/molecules/email-card/index.react'
import { useTranslation } from 'react-i18next'
import { useUser } from '@/features/user/hooks/use-user'
import { EmptyPageCactusAnimation } from '@/components/animations/empty-cactus/index.react'
import { useCompanyContext } from '@/features/auth/hooks/use-company-context'
import { useAuth } from '@/features/auth/hooks/use-auth-react'
import { PhoneCard } from '@/molecules/phone-card/index.react'
import { IbanCard } from '@/molecules/iban-card/index.react'
import { A } from '@/atoms-react/a/A.react'
import { Modal } from '@/atoms-react/modal/index.react'
import { AliasEmail, AliasIBAN, AliasMobile, PublicAliasType } from '@/features/user/types'
import { useSetAliasPublic } from '@/features/user/hooks/use-set-alias-public'
import { NaturalEntityDataType } from '@/features/utils/types'
import { RadioCard } from '@/atoms-react/radio-card/index.react'
import { Alert, AlertType } from '@/atoms-react/alert/index.react'
import { Button } from '@/atoms-react/button/Button.react'
import { Themes } from '@/types/theme'
import { Routes } from '@/features/router'
import { useNavigate } from 'react-router-dom'
import { getFullName } from '@/features/utils/functions.ts'

type AliasesPublicAppProps = React.ComponentPropsWithoutRef<'div'>

export const AliasesPublicApp = ({ ...props }: AliasesPublicAppProps) => {
  const { t } = useTranslation(['pass'])
  const { useUserProfile } = useUser()
  const navigate = useNavigate()
  const { data: user } = useUserProfile()
  const ibans = user?.naturalPerson?.AliasIBAN ?? []
  const emails = user?.naturalPerson?.AliasEmail ?? []
  const phones = user?.naturalPerson?.AliasMobile ?? []
  const email = emails.find((email) => email.Public) ?? null
  const phone = phones.find((phone) => phone.Public) ?? null
  const iban = ibans.find((iban) => iban.Public) ?? null

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalType, setModalType] = useState<PublicAliasType | null>()

  const [selected, setSelected] = useState<AliasMobile | AliasIBAN | AliasEmail | null>(null)

  const { cached } = useAuth()
  const { id, company } = useCompanyContext()
  const naturalPersonMode = !id

  const sectionDivClassName = 'rounded-sm md:px-6 md:py-4'

  const { setAliasPublic } = useSetAliasPublic()

  const onModalConfirm = () => {
    if (!user || !user.naturalPerson) {
      return
    }
    let list: AliasMobile[] | AliasIBAN[] | AliasEmail[] = user.naturalPerson.AliasEmail
    if (modalType === PublicAliasType.Phone) {
      list = user.naturalPerson.AliasMobile
    } else if (modalType === PublicAliasType.Iban) list = user.naturalPerson.AliasIBAN
    onVisibilityChange(selected, list)
    setModalOpen(false)
  }

  const onVisibilityChange = (
    alias: AliasMobile | AliasIBAN | AliasEmail | null,
    aliases: AliasMobile[] | AliasIBAN[] | AliasEmail[]
  ) => {
    const newVisibility = true
    let type = NaturalEntityDataType.AliasMailDto
    if (modalType === PublicAliasType.Phone) {
      type = NaturalEntityDataType.AliasMobileDto
    } else if (modalType === PublicAliasType.Iban) {
      type = NaturalEntityDataType.AliasIBANDto
    }
    if (newVisibility) {
      aliases!
        .filter((alias) => alias.Public)
        .forEach((alias) => {
          setAliasPublic(type, alias.UUID, false)
        })
    }
    if (alias) {
      setAliasPublic(type, alias.UUID, newVisibility)
    }
  }

  const openModal = (type: PublicAliasType) => {
    let selection: AliasMobile | AliasIBAN | AliasEmail | null = email ?? null
    if (type === PublicAliasType.Phone) {
      selection = phone ?? null
    } else if (type === PublicAliasType.Iban) {
      selection = iban ?? null
    }
    setSelected(selection)
    setModalOpen(true)
    setModalType(type)
  }

  return (
    <Column {...props}>
      <LayoutTitle>{t('olkypass.mainPage.navigation_account_menu.link.alias_public')}</LayoutTitle>
      <div className="w-full 2xl:w-2/3 flex flex-col gap-y-10 md:gap-y-6">
        {/* ID and QR code section */}
        <div
          className={twMerge(
            "bg-[url('/images/olkypass/olky-space-background.jpg')] bg-cover bg-center bg-no-repeat flex flex-col-reverse md:flex-row md:items-center justify-between p-6",
            sectionDivClassName
          )}
        >
          <div>
            <SectionTitle className="text-white mb-1">
              {naturalPersonMode
                ? getFullName(user?.naturalPerson)
                : t('olkypass.accountPage.basicInfo.label.company', {
                    company: company?.legal_name
                  })}
            </SectionTitle>
            <Base className="text-white/70 -mb-1 md:-mb-0 md:mr-2">
              {t('olkypass.accountPage.profileSection.label.kyc_identifier')} :{' '}
              {naturalPersonMode ? user?.naturalPerson.UUID : id}
            </Base>
          </div>
          {/* QR Code : just a square now  */}
          <div className="bg-white h-24 w-24 invisible rounded-sm mb-5 md:my-2"></div>
        </div>

        {/* Email section */}
        <div className={twMerge('md:border-2 md:border-slate-50', sectionDivClassName)}>
          <div className="flex items-center justify-between mb-2">
            <Base className="text-slate-300">E-mail</Base>
            {emails.length > 0 && (
              <A onClick={() => openModal(PublicAliasType.Email)}>
                {t('olkypass.aliasPublicPage.change')}
              </A>
            )}
            {emails.length === 0 && (
              <A onClick={() => navigate({ pathname: Routes.account_alias_email })}>
                {t('aliasEmailPage.button.add_email')}
              </A>
            )}
          </div>
          <div>
            {!email && (
              <>
                <div className="w-full flex items-center flex-col space-y-4">
                  <EmptyPageCactusAnimation />
                  <Info>{t('olkypass.aliasPublicPage.no_email')}</Info>
                </div>
              </>
            )}
            {email && <EmailCard email={email} />}
          </div>
        </div>

        {/* Phone section */}
        <div className={twMerge('md:border-2 md:border-slate-50', sectionDivClassName)}>
          <div className="flex items-center justify-between">
            <Base className="text-slate-300">{t('olkypass.aliasPublicPage.phone_number')}</Base>
            {phones.length > 0 && (
              <A onClick={() => openModal(PublicAliasType.Phone)}>
                {t('olkypass.aliasPublicPage.change')}
              </A>
            )}
            {phones.length === 0 && (
              <A
                onClick={() =>
                  navigate({ pathname: Routes.account_alias_mobile, search: '?add=1' })
                }
              >
                {t('aliasMobilePage.button.add_mobile')}
              </A>
            )}
          </div>
          <div>
            {!phone && (
              <>
                <div className="w-full flex items-center flex-col space-y-4">
                  <EmptyPageCactusAnimation />
                  <Info>{t('olkypass.aliasPublicPage.no_phone_number')}</Info>
                </div>
              </>
            )}
            {phone && <PhoneCard phone={phone} />}
          </div>
        </div>

        {/* Iban section */}
        <div className={twMerge('md:border-2 md:border-slate-50', sectionDivClassName)}>
          <div className="flex items-center justify-between">
            <Base className="text-slate-300">IBAN</Base>
            {ibans.length > 0 && (
              <A onClick={() => openModal(PublicAliasType.Iban)}>
                {t('olkypass.aliasPublicPage.change')}
              </A>
            )}
            {ibans.length === 0 && (
              <A
                onClick={() =>
                  navigate({ pathname: Routes.account_alias_payments, search: '?add=1' })
                }
              >
                {t('aliasPaymentsPage.button.add_iban')}
              </A>
            )}
          </div>
          <div>
            {!iban && (
              <>
                <div className="w-full flex items-center flex-col space-y-4">
                  <EmptyPageCactusAnimation />
                  <Info>{t('olkypass.aliasPublicPage.no_iban')}</Info>
                </div>
              </>
            )}
            {iban && <IbanCard iban={iban} />}
          </div>
        </div>
      </div>
      <Modal maxWidthClassName="max-w-2xl" open={modalOpen} onClose={() => setModalOpen(false)}>
        <div className="flex flex-col gap-4">
          <Alert
            type={AlertType.Info}
            title={
              modalType
                ? {
                    [PublicAliasType.Email]: t('olkypass.aliasPublicPage.choose_new_public_email'),
                    [PublicAliasType.Phone]: t('olkypass.aliasPublicPage.choose_new_public_phone'),
                    [PublicAliasType.Iban]: t('olkypass.aliasPublicPage.choose_new_public_iban')
                  }[modalType]
                : ''
            }
            className="mt-4"
          />
          <div className="max-h-96 overflow-y-auto flex flex-col gap-1">
            <RadioCard onClick={() => setSelected(null)} active={selected === null}>
              <Base>{t('olkypass.aliasPublicPage.None')}</Base>
            </RadioCard>
            {modalType === PublicAliasType.Email &&
              user?.naturalPerson?.AliasEmail &&
              user.naturalPerson.AliasEmail.map((aliasEmail) => (
                <RadioCard
                  key={aliasEmail.UUID}
                  className="items-center"
                  active={selected === aliasEmail}
                  onClick={() => setSelected(aliasEmail)}
                >
                  <EmailCard email={aliasEmail} />
                </RadioCard>
              ))}
            {modalType === PublicAliasType.Phone &&
              user?.naturalPerson?.AliasMobile &&
              user.naturalPerson.AliasMobile.map((aliasPhone) => (
                <RadioCard
                  key={aliasPhone.UUID}
                  className="items-center"
                  active={selected === aliasPhone}
                  onClick={() => setSelected(aliasPhone)}
                >
                  <PhoneCard phone={aliasPhone} />
                </RadioCard>
              ))}
            {modalType === PublicAliasType.Iban &&
              user?.naturalPerson?.AliasIBAN &&
              user.naturalPerson.AliasIBAN.map((aliasIban) => (
                <RadioCard
                  key={aliasIban.UUID}
                  className="items-center"
                  active={selected === aliasIban}
                  onClick={() => setSelected(aliasIban)}
                >
                  <IbanCard iban={aliasIban} />
                </RadioCard>
              ))}
          </div>
          <div className="flex justify-end gap-4">
            <Button onClick={() => setModalOpen(false)} theme={Themes.white} className="w-max">
              {t('olkypass.aliasPublicPage.cancel')}
            </Button>
            <Button
              disabled={
                modalType === PublicAliasType.Iban
                  ? selected === iban
                  : modalType === PublicAliasType.Phone
                    ? selected === phone
                    : selected === email
              }
              onClick={onModalConfirm}
              className="w-max"
            >
              {t('olkypass.aliasPublicPage.update')}
            </Button>
          </div>
        </div>
      </Modal>
    </Column>
  )
}
