import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const OrdersTabs = ({ tabs, config }) => {
  const { t } = useTranslation(['wallet'])
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  return (
    <>
    <div className="flex flex-col">
      <div className="flex gap-8 py-4">
        {tabs.map((tab, index) => { 
          let label = tab.label
          switch (tab.label) {
            case 'OPEN':
              label = t('wallet.orders.tabs.OPEN')
              break
            case 'CLOSED':
              label = t('wallet.orders.tabs.CLOSED')
              break
            case 'CANCELED':
              label = t('wallet.orders.tabs.CANCELED')
              break
          }
          return (
            <button
              key={index}
              className={
                `border-solid border-b-4 hover:border-blue-500 dark:text-white ${index === activeTab ? 'border-blue-500' : 'border-transparent'}`
              }
              onClick={() => handleTabClick(index)}
            >
              {label}
            </button>
          )
        })}
      </div>
      <div className={config?.className}>
        {tabs[activeTab].content}
      </div>
    </div>
  </>
  )
}
