import { useAuth } from '@/features/auth/hooks/use-auth-react'
import { useKYCContext } from '@/features/auth/hooks/use-kyc-context'
import { getKycoin } from '@/features/kycoin/hooks/getKycoin'
import { formatNumber } from '@/features/utils/functions'
import { CoinLayout } from '@/molecules/coin-layout/index.react'
import { KYCAlert } from '@/molecules/kyc-alert/index.react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LayoutTitle } from '../_layout/index.react'
import { BalanceCardKycoin } from '../kypay/components/balance-card/balance-card-kycoin.react'
import { TransactionsList } from '../kypay/components/transaction-list/transaction-list.react'

export const KycoinApp = () => {
  const { t } = useTranslation(['wallet'])
  const [search, setSearch] = useState<string>('')
  const { kycSessionStatus, status } = useKYCContext()

  const { data: kycoin, isLoading: isLoadingKycoin } = getKycoin()

  const {
    user: { isLoading: isUserLoading }
  } = useAuth()

  const allowedToKycoin =
    kycSessionStatus?.level === 100 &&
    kycSessionStatus?.ValidForPayout &&
    kycSessionStatus?.ValidForPayIn

  const transactions = kycoin?.transaction.filter((trans) =>
    (trans.Counterparty + ' ' + trans.formatedAmount).includes(search)
  )
 return (
    <>
      <CoinLayout
        search={search}
        setSearch={setSearch}
        title={() => (
          <>
            <LayoutTitle>{t('kycoin.label.main_title')}</LayoutTitle>
          </>
        )}
        sectionTitle={t('kycoin.label.section_title')}
        header={() => !allowedToKycoin && !isUserLoading && <KYCAlert status={status} />}
        body={() => (
          <>
            <BalanceCardKycoin
              amount={formatNumber(kycoin?.balance ?? 0, 0)}
              amountInEuro={formatNumber(kycoin?.balanceEuro ?? 0)}
              balanceType={'onChain'}
              withButton={true}
              loading={isLoadingKycoin}
              disabled={!allowedToKycoin}
              className="max-w-lg"
            />
            <div />
          </>
        )
      
      }
        transactions={{
          title: t('kycoin.section_title_transactions'),
          body: () => (
            <TransactionsList
              listType="kycoin"
              transactions={
                transactions?.map((trans) => ({
                  title: trans.Counterparty,
                  amount: trans.NumberInt,
                  type: trans.Type,
                  date: trans.Date
                })) ?? []
              }
              search={search}
              fracDigits={0}
            />
          )
        }}
      />
    </>
  )
}
