import { QuizStepProps } from '@/features/user/quiz/types.ts'
import { useTranslation } from 'react-i18next'
import { HeaderIbanModal } from '@/molecules/modal-iban-account/header.react.tsx'
import { Base, SectionTitleSmall } from '@/atoms-react/text/Text.react.tsx'
import { Checkbox } from '@/atoms-react/input-checkbox/index.react.tsx'
import DotsNavigation from '@/atoms-react/dots-navigation/DotsNavigation.react.tsx'
import { Button } from '@/atoms-react/button/Button.react.tsx'
import { Themes } from '@/types/theme.ts'
import React from 'react'
import { useQuiz } from '@/features/user/quiz/hooks/use-quiz.ts'
import Loader from '@/atoms-react/loader/Loader.react.tsx'

export const AccountReasonStep = ({
  setStep,
  quizResponses,
  setQuizResponses,
  stepSettings
}: QuizStepProps) => {
  const { t, i18n } = useTranslation(['pass'])
  const { AccountReasonsQuery } = useQuiz()
  const { data: account_reasons, isLoading: isLoadingAccountReasons } = AccountReasonsQuery(
    i18n.language
  )
  const handleCheckboxChange = (id: string, isChecked: boolean) =>
    setQuizResponses((prev) => {
      const newElement = isChecked
        ? [...prev.account_reasons, { id }]
        : prev.account_reasons.filter((income) => Number(income.id) !== Number(id))

      return {
        ...prev,
        account_reasons: newElement
      }
    })

  const isQuizAnswered = () => {
    if (!quizResponses?.account_reasons?.length) return false
    return true
  }

  return (
    <>
      <SectionTitleSmall>
        {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.steps.account_reasons.question')}
      </SectionTitleSmall>

      {isLoadingAccountReasons && (
        <div className="flex flex-1 items-center justify-center mt-4 mb-4">
          <Loader className="text-gray-500" />
        </div>
      )}
      {!isLoadingAccountReasons && (
        <>
          <div className="space-y-4 my-4 w-full mt-4">
            {account_reasons &&
              account_reasons.map((c, index) => (
                <Checkbox
                  key={c.id}
                  value={
                    quizResponses?.account_reasons &&
                    quizResponses.account_reasons.some(
                      (income) => Number(income.id) === Number(c.id)
                    )
                  }
                  onChange={(value) => handleCheckboxChange(c.id, value)}
                >
                  <Base className="mb-1 cursor-pointer">
                    {i18n.language === 'fr' ? c.name : c.english_name}
                  </Base>
                </Checkbox>
              ))}
          </div>
          <div className="flex flex-col items-center mt-auto mb-4">
            <DotsNavigation total={7} current={stepSettings.current} className="mb-4" />
            <div className="w-full flex flex-col-reverse gap-y-2 md:flex-row md:gap-x-2">
              <Button
                theme={Themes.white}
                onClick={() => {
                  setStep(stepSettings.previous)
                }}
              >
                {t('olkypass.general.go_back')}
              </Button>

              <Button
                disabled={!isQuizAnswered()}
                onClick={() => {
                  setStep(stepSettings.next)
                }}
              >
                {t('olkypass.general.continue')}
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  )
}
