import { FAVOURITE_COUNTRY_CODES, PHONE_COUNTRY_CODES } from '@/features/utils/constants'
import { getAsYouType, getExample, parsePhoneNumber } from 'awesome-phonenumber'
import _ from 'lodash'
import React, { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import InputLabel from './Input.react'

import Flag from '@/atoms/flag/Flag.react'
import { useUser } from '@/features/user/hooks/use-user'
import { stringToPhoneCountryCode } from '@/features/utils/functions'
import { useLocale } from '@/features/utils/hooks/use-locale'
import { Dropdown } from '../dropdown/index.react'
import { Base } from '../text/Text.react'

export type PhoneNumber = {
  phone: string | undefined
  international: string | undefined
  countryCode: string
  countryPrefix: string
  valid: boolean
  autoComplete?: string
}

type InputPhoneProps = {
  value: PhoneNumber
  initialCountry?: string
  onChange?: (value: PhoneNumber) => void
  className?: React.ComponentPropsWithoutRef<'div'>['className']
  autoComplete?: string
} & Omit<Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'>, 'className'>

function InputPhone({
  value,
  onChange = () => {},
  initialCountry = stringToPhoneCountryCode(navigator.language),
  className,
  ...props
}: InputPhoneProps) {
  const [country, setCountry] = useState<string>(initialCountry)

  const { locale } = useLocale()
  const { useCountries } = useUser()
  const { data: countries } = useCountries(locale.language)

  const findCountryByKey = (key: string) => {
    return (countries?.countries ?? []).find(
      (country) => country.alpha2.toLowerCase() === stringToPhoneCountryCode(key).toLowerCase()
    )
  }

  const getPlaceholder = () => {
    if (!country) return 'XX XX XX XX XX'

    try {
      const placeholder = getExample(country, 'mobile').number?.national.replace(/[0-9]/g, 'X')
      return placeholder
    } catch (e) {
      return ''
    }
  }

  if (!initialCountry) return

  return (
    <div className={twMerge('flex gap-x-3', className)}>
      <Dropdown
        items={(countries?.countries ?? []).map(({ alpha2: key, defaultName: value }) => ({
          groupKey: FAVOURITE_COUNTRY_CODES.includes(key.toLocaleLowerCase())
            ? 'default'
            : undefined,
          key,
          value,
          render: ({ inDropdown }) => {
            return (
              <div className={'w-full flex items-center'}>
                <Flag
                  countryCode={stringToPhoneCountryCode(key)}
                  className="!w-6 !h-6 rounded-sm"
                />
                <div
                  className={
                    'w-full flex justify-between items-center ml-2 space-x-4 overflow-hidden'
                  }
                >
                  {inDropdown && (
                    <Base className="text-ellipsis whitespace-nowrap overflow-hidden">{value}</Base>
                  )}
                  <Base className={'text-slate-500 dark:text-white shrink-0'}>
                    {PHONE_COUNTRY_CODES[stringToPhoneCountryCode(key)]}
                  </Base>
                </div>
              </div>
            )
          }
        }))}
        groups={[{ key: 'default' }]}
        showChevrons={false}
        search={{
          custom: (searchValue, item) => {
            const itemCountry = findCountryByKey(item.key)?.defaultName
            return (itemCountry + ' ' + PHONE_COUNTRY_CODES[stringToPhoneCountryCode(item.key)])
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          }
        }}
        value={country || 'FR'}
        onChange={({ key }) => {
          setCountry(key)
        }}
        className={'min-w-max'}
      />
      <InputLabel
        autoComplete={props.autoComplete}
        value={value?.phone ?? ''}
        isPhoneNumber={true}
        placeholder={getPlaceholder()}
        format={(value: string) => {
          const safeValue = value.replace(/[^0-9+]/g, '').substring(0, 11)
          if (safeValue.match(/^\+/)) {
            const newRegion = parsePhoneNumber(safeValue)?.regionCode
            if (newRegion !== country) setCountry(newRegion || country)
          }
          const asYouType = getAsYouType(country)
          asYouType.reset(safeValue)

          return asYouType.getPhoneNumber().number?.input ?? ''
        }}
        onChange={(value: string) => {
          const phone = parsePhoneNumber(value, { regionCode: country })
          onChange({
            phone: phone.number?.national ?? phone.number?.international ?? phone.number?.input,
            international: phone.number?.international,
            valid: phone.valid,
            countryCode: country,
            countryPrefix: phone?.countryCode ? '+' + phone?.countryCode : '+33'
          })
        }}
        {..._.omit(props, 'className', 'value', 'onChange', 'key')}
      />
    </div>
  )
}

export default InputPhone
