import { HeaderIbanModal } from '@/molecules/modal-iban-account/header.react.tsx'
import { Base, SectionTitleSmall } from '@/atoms-react/text/Text.react.tsx'
import { Checkbox } from '@/atoms-react/input-checkbox/index.react.tsx'
import InputLabel from '@/atoms-react/input/Input.react.tsx'
import DotsNavigation from '@/atoms-react/dots-navigation/DotsNavigation.react.tsx'
import { Button } from '@/atoms-react/button/Button.react.tsx'
import { Themes } from '@/types/theme.ts'
import React from 'react'
import { useQuiz } from '@/features/user/quiz/hooks/use-quiz.ts'
import { useTranslation } from 'react-i18next'
import { QuizStepProps, SourceIncome } from '@/features/user/quiz/types.ts'
import Loader from '@/atoms-react/loader/Loader.react.tsx'
export const SourceIncomeStep = ({
  setStep,
  quizResponses,
  setQuizResponses,
  stepSettings
}: QuizStepProps) => {
  const { t, i18n } = useTranslation(['pass'])
  const { SourcesIncomeQuery } = useQuiz()
  const { data: response, isLoading: isLoadingSourcesIncome } = SourcesIncomeQuery(i18n.language)
  const sources_income: SourceIncome[] = response?.sourceIncomeCodes
  const codeOfOtherIncome = sources_income?.find((item) => item.Nom === 'Autres')?.id

  const handleCheckboxChange = (id: string, isChecked: boolean) =>
    setQuizResponses((prev) => {
      const newSourcesIncome = isChecked
        ? [...prev.sources_income, { id, comment: '' }]
        : prev.sources_income.filter((income) => Number(income.id) !== Number(id))

      return {
        ...prev,
        sources_income: newSourcesIncome
      }
    })

  const handleCommentChange = (id, otherInputIncomes) => {
    setQuizResponses((prev) => {
      const updatedSourcesIncome = prev.sources_income.map((income) =>
        income.id === id ? { ...income, comment: otherInputIncomes } : income
      )
      return {
        ...prev,
        sources_income: updatedSourcesIncome
      }
    })
  }

  const isQuizAnswered = () => {
    if (!quizResponses?.sources_income?.length) return false
    const otherIncomes = quizResponses?.sources_income?.find(
      (income) => income.id === codeOfOtherIncome
    )
    if (otherIncomes && otherIncomes?.comment.trim() === '') return false

    return true
  }

  return (
    <>
      <SectionTitleSmall>
        {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.steps.incomes.question')}
      </SectionTitleSmall>

      {isLoadingSourcesIncome && (
        <div className="flex flex-1 items-center justify-center mt-4 mb-4">
          <Loader className="text-gray-500" />
        </div>
      )}
      {!isLoadingSourcesIncome && (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 my-4 mt-4">
            {sources_income &&
              sources_income.map((c, index) => (
                <Checkbox
                  key={c.id}
                  value={
                    quizResponses?.sources_income &&
                    quizResponses.sources_income.some(
                      (income) => Number(income.id) === Number(c.id)
                    )
                  }
                  onChange={(value) => handleCheckboxChange(c.id, value)}
                >
                  <Base className="mb-1 cursor-pointer">
                    {i18n.language === 'fr' ? c.Nom : c.NomEn}
                  </Base>
                </Checkbox>
              ))}
          </div>
          {quizResponses?.sources_income &&
            quizResponses.sources_income.some((income) => income.id === codeOfOtherIncome) && (
              <div className={'mb-4'}>
                <InputLabel
                  label={t(
                    'olkypass.mainPage.olkypass.modalOpenOlkyPay.steps.incomes.responses.others_comment'
                  )}
                  value={
                    quizResponses.sources_income.find((income) => income.id === codeOfOtherIncome)
                      ?.comment
                  }
                  onChange={(value: string) => handleCommentChange(codeOfOtherIncome, value)}
                  className="flex-1 mb-1 md:mb-0"
                />
              </div>
            )}
          <div className="flex flex-col items-center mt-auto mb-4">
            <DotsNavigation total={7} current={stepSettings.current} className="mb-4" />
            <div className="w-full flex flex-col-reverse gap-y-2 md:flex-row md:gap-x-2">
              <Button
                theme={Themes.white}
                onClick={() => {
                  setStep(stepSettings.previous)
                }}
              >
                {t('olkypass.general.go_back')}
              </Button>

              <Button
                disabled={!isQuizAnswered()}
                onClick={() => {
                  setStep(stepSettings.next)
                }}
              >
                {t('olkypass.general.continue')}
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  )
}
