import { A } from '@/atoms-react/a/A.react'
import { Button } from '@/atoms-react/button/Button.react'
import { Base, SectionTitle, Title } from '@/atoms-react/text/Text.react'
import { useCompanyContext } from '@/features/auth/hooks/use-company-context'
import { Themes } from '@/types/theme'
import { Bars3Icon } from '@heroicons/react/20/solid'
import { ReactNode, useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { atom, useRecoilState, useSetRecoilState } from 'recoil'
import { twMerge } from 'tailwind-merge'
import { MenuAccount } from './menu-account.react'
import { MenuWallet } from './menu-wallet.react'
import { Menu } from './menu.react'
import { BackButton } from '../../flow/components/flow-header.react'
import { useAuth } from '@/features/auth/hooks/use-auth-react'
import Loader from '@/atoms-react/loader/Loader.react'
import { Routes } from '@/features/router'
import { useMediaQuery } from 'usehooks-ts'
import { useNavigate } from 'react-router-dom'

const SideMenuAtom = atom({
  key: 'SideMenuAtom',
  default: false
})

export type LayoutTitleProps = {
  children?: ReactNode
  back?: boolean
  action?: ReactNode
}

enum MenuType {
  Account,
  Wallet,
  Main
}

export const LayoutTitle = ({ children, back, action }: LayoutTitleProps) => {
  const { pathname } = useLocation()
  const openMenu = useSetRecoilState(SideMenuAtom)
  useEffect(() => openMenu(false), [pathname])

  return (
    <>
      <div className="mb-7 flex flex-row w-full justify-between items-center flex-nowrap lg:justify-between">
        <div className="w-16 shrink-0 justify-start flex items-center lg:hidden">
          <Button
            size="md"
            theme={Themes.iconButton}
            icon={Bars3Icon}
            onClick={() => openMenu(true)}
          />
        </div>
        <div className="flex  items-center justify-center text-center lg:text-left overflow-hidden whitespace-nowrap text-ellipsis w-max">
          {back && <BackButton className={'mb-0 mr-4'} />}
          <SectionTitle className="overflow-hidden whitespace-nowrap text-ellipsis lg:hidden block">
            {children}
          </SectionTitle>
          <Title className="overflow-hidden whitespace-nowrap text-ellipsis lg:block hidden">
            {children}
          </Title>
        </div>
        <div className="shrink-0 justify-end flex items-center lg:hidden min-w-16"></div>
        <div className="hidden lg:flex justify-center items-center">{action}</div>
      </div>
      <div className={'flex lg:grow justify-center lg:hidden mb-4'}>{action}</div>
    </>
  )
}

export const Layout = () => {
  const { user } = useAuth()
  const [open, setOpen] = useRecoilState(SideMenuAtom)
  const { id } = useCompanyContext()

  const isNotMobile = useMediaQuery('(min-width: 768px)')
  const [menu, setMenu] = useState<MenuType>(location.pathname.includes(Routes.account_base) ? MenuType.Account : (location.pathname.includes(Routes.wallet_base) ? MenuType.Wallet : MenuType.Main))

  const navigate = useNavigate()

  useEffect(() => {
    setMenu(location.pathname.includes(Routes.account_base) ? MenuType.Account : (location.pathname.includes(Routes.wallet_base) ? MenuType.Wallet : MenuType.Main))
  }, [location.pathname])

  if (user.isPending || !user.data?.naturalPerson?.UUID) {
    return (
      <>
        <Loader className="absolute m-auto left-0 right-0 top-0 bottom-0" />
      </>
    )
  }

  return (
    <div className="grow flex flex-row h-full">
      <div
        className={twMerge(
          'absolute left-0 top-0 w-full h-full bg-black z-20 transition-all',
          open ? 'opacity-50' : 'opacity-0 pointer-events-none'
        )}
        onClick={() => setOpen(false)}
      />
      {(isNotMobile || menu === MenuType.Main) && <Menu open={open} smallVersion={(menu === MenuType.Account)||(menu === MenuType.Wallet)}
        onOlkyPassClick={() => {
          setMenu(MenuType.Account)
          if (isNotMobile && !location.pathname.includes(Routes.account_identity)) {
            navigate(Routes.account_identity)
          }
        }}
        onOlkyWalletClick={() => {
          setMenu(MenuType.Wallet)
          if (isNotMobile && !location.pathname.includes(Routes.wallet_dashboard)) {
            navigate(Routes.wallet_dashboard)
          }
        }}
      />}
      {menu === MenuType.Account && (
        <MenuAccount
          open={open}
          onClose={() => {
            setMenu(MenuType.Main)
            if (isNotMobile) {
              navigate(Routes.dashboard)
            }
          }}
          className="absolute left-48"
        />
      )}
      {menu === MenuType.Wallet && (
        <MenuWallet
          open={open}
          onClose={() => {
            setMenu(MenuType.Main)
            if (isNotMobile) {
              navigate(Routes.dashboard)
            }
          }}
          className="absolute left-48"
        />
      )}

      <div
        className="grow px-4 py-4 lg:px-8 lg:py-6 relative overflow-y-auto overflow-x-hidden z-10"
        key={id}
      >
        <Outlet />
      </div>
    </div>
  )
}

export const MenuItem = ({
  to,
  onClick,
  icon,
  smallVersion,
  children,
  isVisible = true
}: {
  to?: string
  onClick?: () => void
  icon: ({ className }: { className: string }) => ReactNode | JSX.Element
  smallVersion?: boolean
  children?: any
  isVisible?: boolean
}) => {
  const { pathname } = useLocation()
  const active = pathname.indexOf(to || 'none') === 0

  return (
    <>
      {isVisible && (
        <A
          to={to}
          onClick={onClick}
          noColor
          className={twMerge(
            'cursor-pointer flex flex-row items-center py-2 text-slate-500 dark:text-slate-300 hover:text-slate-900 dark:hover:text-slate-200',
            active
              ? 'text-blue-500 dark:text-blue-500 hover:text-blue-500 dark:hover:text-blue-500'
              : ''
          )}
        >
          <div
            className={twMerge(
              'bg-slate-50 dark:bg-opacity-15 rounded flex items-center justify-center shrink-0',
              active ? 'bg-blue-100 dark:bg-white dark:bg-opacity-100' : '',
          'h-8 w-8'
            )}
          >
            {icon({ className: 'h-4 w-4' })}
          </div>
    
      <Base
            className={twMerge(
              'grow ml-3 -mb-px whitespace-nowrap transition-opacity opacity-100 text-ellipsis overflow-hidden',
              active && 'dark:text-white', smallVersion && 'opacity-0'
            )}
            noColor
          >
            {children}
          </Base>
    
    </A>
      )}
    </>
  )
}
