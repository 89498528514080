import { QuizStepProps, SourceIncome } from '@/features/user/quiz/types.ts'
import { useTranslation } from 'react-i18next'
import { useQuiz } from '@/features/user/quiz/hooks/use-quiz.ts'
import { HeaderIbanModal } from '@/molecules/modal-iban-account/header.react.tsx'
import { Base, Info, SectionTitleSmall } from '@/atoms-react/text/Text.react.tsx'
import InputLabel from '@/atoms-react/input/Input.react.tsx'
import DotsNavigation from '@/atoms-react/dots-navigation/DotsNavigation.react.tsx'
import { Button } from '@/atoms-react/button/Button.react.tsx'
import { Themes } from '@/types/theme.ts'
import React, { useState } from 'react'
import { RadioCard } from '@/atoms-react/radio-card/index.react.tsx'
import toast from 'react-hot-toast'
enum ModalRadiosTransKeys {
  YesCommunicate = 'yes_communicate',
  YesNotCommunicate = 'yes_not_communicate',
  Nope = 'no'
}
export const EuropeanAccountStep = ({
  setStep,
  quizResponses,
  setQuizResponses,
  stepSettings
}: QuizStepProps) => {
  const { t, i18n } = useTranslation(['pass'])
  const [loading, setLoading] = useState(false)

  const { useCheckIbanQuizMutation } = useQuiz()
  const { mutateAsync: checkQuizIban } = useCheckIbanQuizMutation()
  const handleRadioBoxChange = (selectedOption) =>
    setQuizResponses((prev) => ({
      ...prev,
      european_account: { trans_key: selectedOption }
    }))
  const handleIbanChange = (iban) =>
    setQuizResponses((prev) => ({
      ...prev,
      european_account: { trans_key: prev.european_account.trans_key, iban: iban }
    }))
  const isQuizAnswered = () => {
    if (
      !quizResponses?.european_account ||
      Object.keys(quizResponses?.european_account).length === 0
    ) {
      return false
    }
    if (quizResponses?.european_account?.trans_key === null) {
      return false
    }
    if (
      quizResponses?.european_account?.trans_key === ModalRadiosTransKeys.YesCommunicate &&
      (!quizResponses?.european_account?.iban ||
        quizResponses?.european_account?.iban?.trim() === '')
    ) {
      return false
    }
    return true
  }
  const handleOnClickNext = async () => {
    try {
      if (quizResponses?.european_account?.trans_key !== ModalRadiosTransKeys.YesCommunicate) {
        setStep(stepSettings.condition_next)
      } else {
        setLoading(true)
        await checkQuizIban(quizResponses?.european_account?.iban)
        setLoading(false)
        setStep(stepSettings.next)
      }
    } catch (e) {
      setLoading(false)
      toast.error(t('olkypass.accountPage.notifications.error.iban.create'))
    }
  }
  return (
    <>
      <SectionTitleSmall>
        {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.steps.european_account.question')}
      </SectionTitleSmall>
      <Info className="block mt-2 mb-4">
        {t(
          'olkypass.mainPage.olkypass.modalOpenOlkyPay.steps.european_account.helpers.european_countries'
        )}
      </Info>
      <div className="space-y-4 my-4 w-full mt-auto">
        {[
          ModalRadiosTransKeys.YesCommunicate,
          ModalRadiosTransKeys.YesNotCommunicate,
          ModalRadiosTransKeys.Nope
        ].map((m) => (
          <RadioCard
            active={
              quizResponses?.european_account && quizResponses?.european_account?.trans_key === m
            }
            key={m}
            onClick={() => handleRadioBoxChange(m)}
          >
            <Base>
              {t(
                'olkypass.mainPage.olkypass.modalOpenOlkyPay.steps.european_account.responses.' + m
              )}
            </Base>
            {quizResponses?.european_account &&
              quizResponses?.european_account?.trans_key === m && (
                <Info className="text-sky-500 dark:text-sky-500">
                  {t(
                    'olkypass.mainPage.olkypass.modalOpenOlkyPay.steps.european_account.helpers.' +
                      m
                  )}
                </Info>
              )}
            {quizResponses?.european_account &&
              quizResponses?.european_account?.trans_key === m &&
              m === ModalRadiosTransKeys.YesCommunicate && (
                <div className={'mt-2 mb-4'}>
                  <InputLabel
                    label=""
                    value={quizResponses?.european_account?.iban}
                    onChange={(value: string) => handleIbanChange(value)}
                    className="flex-1 mb-1 md:mb-0"
                  />
                </div>
              )}
          </RadioCard>
        ))}
      </div>
      <div className="flex flex-col items-center mt-auto mb-4">
        <DotsNavigation total={7} current={stepSettings.current} className="mb-4" />
        <div className="w-full flex flex-col-reverse gap-y-2 md:flex-row md:gap-x-2">
          <Button
            theme={Themes.white}
            onClick={() => {
              setStep(stepSettings.previous)
            }}
          >
            {t('olkypass.general.go_back')}
          </Button>

          <Button
            disabled={!isQuizAnswered()}
            loading={loading}
            onClick={() => {
              handleOnClickNext()
            }}
          >
            {t('olkypass.general.continue')}
          </Button>
        </div>
      </div>
    </>
  )
}
