'use client'
import { CoinLayout } from '@/molecules/coin-layout/index.react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LayoutTitle } from '../../../_layout/index.react'
import { CardWallet } from '../wallet-card/card-wallet.react'
import InputLabel from '@/atoms-react/input/Input.react'
import { Button } from '@/atoms-react/button/Button.react'
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/solid'
import { Themes } from '@/types/theme'
import { useWallet } from '@/features/wallet/hooks/use-wallet'
import Loader from '@/atoms-react/loader/Loader.react'

export const WalletCryptoRoot = () => {
  const { t } = useTranslation(['wallet'])
  const [search, setSearch] = useState<string>('')

  const { currencyQuery } = useWallet()
  const { data: currency, isLoading: isCurrencyLoading } = currencyQuery()

  return (
    <>
      <CoinLayout
        search={search}
        setSearch={setSearch}
        title={() => (
          <>
            <LayoutTitle>
              {t('wallet.crypto.label.main_title')}
            </LayoutTitle>
          </>
        )}
        sectionTitle={''} // For the sub-title
        header={() => (
          <div className="flex flex-row gap-4">
            <InputLabel
              value={search}
              onChange={(value) => setSearch(value)}
              bgClass="bg-white"
              placeholder={t('wallet.crypto.label.input_filter')}
            />
            <div className='w-full'>
              <Button
                className={'text-base text-black w-auto h-full'} 
                size='sm'
                onClick={()=>{}}  
                theme={Themes.white}
                icon={AdjustmentsHorizontalIcon}
                iconPosition="before"                
              >
                {t('wallet.crypto.label.btn_filter')}
              </Button>
            </div>
          </div>
        )}
        body={() => (
          <>
            {!isCurrencyLoading && (
              <div className="grid grid-cols-3 gap-4 w-full">
                <CardWallet Type="market" config={{'format':'grid', 'data': currency}} />
              </div> 
            )}
            {isCurrencyLoading && <Loader className={'w-8 h-8 text-blue-500'} />}
          </>
        )}
      />
    </>
  )
}
