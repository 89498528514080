import { Modal, ModalContent } from '@/atoms-react/modal/index.react'
import { SolidAuthnForm } from './sollid-authn-process.react'
import { useEffect } from 'react'

type SolidAuthnModalProps = {
  action: string
  onClose: () => void
  onValidated: () => void
}

export const SolidAuthnModal = ({ action, onClose, onValidated }: SolidAuthnModalProps) => {
  /* useEffect(() => {
        onValidated();
    }, [onValidated]);
    return null; */
  return (
    <Modal
      className={'sm:m-0 m-0 sm:w-full md:w-9/12'}
      maxWidthClassName={'max-w-xl'}
      open={true}
      onClose={onClose}
    >
      <ModalContent title={''}>
        <SolidAuthnForm action={action} onValidated={onValidated} />
      </ModalContent>
    </Modal>
  )
}
