import { CompanyDTO } from '@/features/company/types'
import { AddressType } from '@/features/user/types'
import { useMutation, useQuery } from '@tanstack/react-query'
import { KYCApiClient } from '../api-client/api-client'
import { CreateKYCDocument, CreateKYCSession, EditKYCSession, KYCSession } from '../types'

export type useKYCProps = {
  company: CompanyDTO
  address: AddressType
}

export const useKYC = () => {
  const KYCStateQuery = (UUID: string | null) =>
    useQuery({
      queryKey: ['kyc', 'state', UUID],
      queryFn: () => (UUID ? KYCApiClient.getKYCSessionState({ UUID }) : null),
      enabled: !!UUID
    })

  const KYCDocumentsQuery = (UUID: string | null) =>
    useQuery({
      queryKey: ['kyc', 'documents'],
      queryFn: () => KYCApiClient.getKYCDocuments(UUID ?? ''),
      enabled: !!UUID
    })

  const KYCDocumentTypesQuery = () =>
    useQuery({
      queryKey: ['kyc', 'documents', 'types'],
      queryFn: () => KYCApiClient.getKYCDocumentTypes()
    })

  const createKYCSessionMutation = () => {
    return useMutation<KYCSession, Error, CreateKYCSession>({
      mutationFn: async (data) => {
        if (!data.sessionSenario && !data.sessionType)
          throw Error('createKYCSessionMutation - must define sessionSenario or sessionType')

        const preparation = await KYCApiClient.prepareKYCSession(data)
        const KYCSession = await KYCApiClient.createKYCSession({
          ...data,
          sessionId: preparation.session_id,
          AbstractEntity: data.UUID
        })

        return KYCSession
      }
    })
  }

  const editKYCSessionMutation = () => {
    return useMutation<KYCSession, Error, EditKYCSession>({
      mutationFn: async (data) => {
        const document = await KYCApiClient.editKYCSession(data)
        return document
      }
    })
  }

  const updateDocumentMutation = () => {
    return useMutation<KYCSession, Error, CreateKYCDocument>({
      mutationFn: async (data) => {
        const document = await KYCApiClient.updateDocument(data)
        return document
      }
    })
  }
  const updateQuizVideoQuestionMutation = () => {
    return useMutation<KYCSession, Error, CreateKYCDocument>({
      mutationFn: async (data) => {
        const item = await KYCApiClient.updateQuizVideoQuestion(data)
        return item
      }
    })
  }

  return {
    KYCStateQuery,
    KYCDocumentsQuery,
    KYCDocumentTypesQuery,
    createKYCSessionMutation,
    updateDocumentMutation,
    editKYCSessionMutation,
    updateQuizVideoQuestionMutation
  }
}
