import { Button, ButtonProps } from '@/atoms-react/button/Button.react'
import { Base, Title } from '@/atoms-react/text/Text.react'
import { Themes } from '@/types/theme'
import { ArrowLeftIcon } from '@heroicons/react/20/solid'
import React, { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import { animatedNext } from './layout/AnimatedForm.react'
import { OlkyPassRoutes } from '@/app/olkypass/utils/routes.ts'
import { useNavigate, useParams } from 'react-router-dom'
import { getRoute } from '@/app/olkypass/utils/routes'
import { Routes } from '@/features/router'

export const FlowHeader = ({
  title,
  description,
  back,
  goHomePage
}: {
  title: string
  description?: string | ReactNode
  back?: boolean
  goHomePage?: boolean
}) => (
  <div>
    {(back || back === undefined) && !goHomePage && <BackButton />}
    {goHomePage !== undefined && goHomePage && <HomeButton />}
    <Title className={'pb-4'}>{title}</Title>
    {description && <Base className={'pb-8'}>{description}</Base>}
  </div>
)

export type BackButtonProps = {
  onClick?: (event: React.MouseEvent) => void
} & Omit<ButtonProps, 'onClick'>

export const BackButton = ({ className, onClick, ...props }: BackButtonProps) => {
  const navigate = useNavigate()
  const { session_uuid, block, step, substep } = useParams()
  const handleClick = () => {
    animatedNext('back', async () => {
      if (step === 'identity') {
        switch (substep) {
          case 'person':
            navigate(
              getRoute(Routes.flow_substep, { session_uuid, block, step, substep: 'welcome' })
            )
            break
          case 'birth':
            navigate(
              getRoute(Routes.flow_substep, { session_uuid, block, step, substep: 'person' })
            )
            break
          case 'residence':
            navigate(getRoute(Routes.flow_substep, { session_uuid, block, step, substep: 'birth' }))
            break
          case 'activity':
            navigate(
              getRoute(Routes.flow_substep, { session_uuid, block, step, substep: 'residence' })
            )
            break
          default:
            break
        }
      } else navigate(-1)
    })
  }

  return (
    <Button
      theme={Themes.iconButton}
      icon={ArrowLeftIcon}
      size="lg"
      className={twMerge('mb-4', className)}
      onClick={(e) => (onClick ? onClick(e) : handleClick())}
      {...props}
    />
  )
}

export const HomeButton = ({ className, onClick, ...props }: BackButtonProps) => {
  const navigate = useNavigate()
  const handleClick = () => {
    animatedNext('back', async () => {
      navigate(OlkyPassRoutes.pass)
    })
  }

  return (
    <Button
      theme={Themes.iconButton}
      icon={ArrowLeftIcon}
      size="lg"
      className={twMerge('mb-4', className)}
      onClick={(e) => (onClick ? onClick(e) : handleClick())}
      {...props}
    />
  )
}
