'use client'
import { CoinLayout } from '@/molecules/coin-layout/index.react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LayoutTitle } from '../../../_layout/index.react'
import { CardWallet } from '../wallet-card/card-wallet.react'
import { CardStack } from '@/molecules/card-stack/card-stack.react'
import { useWallet } from '@/features/wallet/hooks/use-wallet'
import Loader from '@/atoms-react/loader/Loader.react'

export const WalletTransferRoot = () => {
  const { t } = useTranslation(['wallet'])
  const [search, setSearch] = useState<string>('')

  const { currencyQuery } = useWallet()
  const { data: currency, isLoading: isCurrencyLoading } = currencyQuery()

  return (
    <>
      <CoinLayout
        search={search}
        setSearch={setSearch}
        title={() => (
          <>
            <LayoutTitle>
              {t('wallet.transfer.label.main_title')}
            </LayoutTitle>
          </>
        )}
        sectionTitle={''} // For the sub-title
        header={() => ''}
        body={() => (
          <div className="grid grid-cols-3 gap-4 w-full">
            {!isCurrencyLoading && (
              <>
                <CardWallet Type="transfer" className="col-span-2" config={{'data': currency}} />
                <CardStackWallet />
              </>
            )}
            {isCurrencyLoading && <Loader className={'w-8 h-8 text-blue-500'} />}
          </div>
        )}
      />
    </>
  )
}

export function CardStackWallet() {
  const { t } = useTranslation(['pass'])
  const CARDS = [
    {
      id: 0,
      title: t('olkypass.WalletDashboardPage.cardsExplanationsKypay.title.1'),
      content: t('olkypass.WalletDashboardPage.cardsExplanationsKypay.content.1')
    },
    {
      id: 1,
      title: t('olkypass.WalletDashboardPage.cardsExplanationsKypay.title.2'),
      content: t('olkypass.WalletDashboardPage.cardsExplanationsKypay.content.2')
    },
    {
      id: 2,
      title: t('olkypass.WalletDashboardPage.cardsExplanationsKypay.title.3'),
      content: t('olkypass.WalletDashboardPage.cardsExplanationsKypay.content.3')
    }
  ]
  return (
    <div className='flex items-center w-full max-w-lg'>
      <CardStack items={CARDS} />
    </div>
  )
}