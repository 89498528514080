import { WalletRoot } from './components/wallet-root/index.react'
import { WalletFormRoot } from './components/wallet-root/index-form.react'
import { WalletBuyRoot } from './components/wallet-root/index-buy.react'
import { WalletSellRoot } from './components/wallet-root/index-sell.react'
import { WalletConvertRoot } from './components/wallet-root/index-convert.react'
import { WalletTransferRoot } from './components/wallet-root/index-transfer.react'
import { WalletCryptoRoot } from './components/wallet-root/index-crypto.react'
import { WalletCryptoDetailsRoot } from './components/wallet-root/index-crypto-details.react'

export const WalletApp = () => {
  return <WalletRoot />
}

export const WalletBuy = () => {
  return <WalletBuyRoot />
}

export const WalletSell = () => {
  return <WalletSellRoot />
}

export const WalletConvert = () => {
  return <WalletConvertRoot />
}

export const WalletTransfer = () => {
  return <WalletTransferRoot />
}

export const WalletCryptoList = () => {
  return <WalletCryptoRoot />
}

export const WalletCryptoDetails = () => {
  return <WalletCryptoDetailsRoot />
}

export const WalletForm = () => {
  return <WalletFormRoot />
}