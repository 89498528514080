import { A } from '@/atoms-react/a/A.react'
import { Button } from '@/atoms-react/button/Button.react'
import { Dropdown } from '@/atoms-react/dropdown/index.react'
import InputLabel from '@/atoms-react/input/Input.react'
import { Modal, ModalContent, ModalProps } from '@/atoms-react/modal/index.react'
import { Info } from '@/atoms-react/text/Text.react'
import { useAuth } from '@/features/auth/hooks/use-auth-react'
import { useKypay } from '@/features/kypay/hooks/use-kypay'
import { Routes } from '@/features/router'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatCurrencyInput, formatNumber } from '@/features/utils/functions.ts'

export const ModalWithdrawBank = ({ ...props }: ModalProps) => {
  const { t } = useTranslation(['wallet'])
  const [amountString, setAmountString] = useState<string>('0,00')
  const [amount, setAmount] = useState<number | null>(null)
  const [iban, setIban] = useState<string>('')

  const { user } = useAuth()
  const { withdrawKypayBalanceMutation } = useKypay()
  const { mutateAsync: withdrawKypayBalance } = withdrawKypayBalanceMutation()
  const resetInputs = () => {
    setAmount(0)
    setAmountString(formatNumber(0))
  }
  const inputRef = useRef<HTMLInputElement | null>()
  useEffect(() => {
    forceCursorPosition()
  }, [amountString])

  const forceCursorPosition = () => {
    if (inputRef.current) {
      const inputLength = inputRef.current.value.length
      inputRef.current.setSelectionRange(inputLength, inputLength) // Force cursor to the end on focus
    }
  }
  return (
    <Modal
      className="sm:w-full w-full max-w-lg"
      {...props}
      onClose={() => {
        resetInputs()
        props.onClose?.()
      }}
    >
      <ModalContent title={t('kypay.modal_withdraw_funds.label.title')}>
        <div className={'mt-4 opacity-1'}>
          <InputLabel
            value={amountString}
            onClick={forceCursorPosition}
            onChange={(value: string) => {
              const formattedValue = formatCurrencyInput(value)
              const parsed = parseFloat(formattedValue)
              if (!isNaN(parsed)) {
                setAmount(parsed)
                setAmountString(formatNumber(parsed))
              } else {
                setAmountString(formatNumber(0))
              }
            }}
            suffixIcon={() => <span>€</span>}
            type="text"
            min="0.00"
            max="10000.00"
            step="0.01"
            label={t('kypay.modal_transfer_fund.input.amount_label')}
            placeholder={t('kypay.modal_transfer_fund.input.amount_placeholder')}
            ref={inputRef}
          />

          <InputLabel
            label={t('kypay.modal_withdraw_funds.input.bank_account_label')}
            className={'mt-6 mb-2'}
            inputComponent={
              <>
                {!!(user.data?.naturalPerson?.AliasIBAN || []).length && (
                  <Dropdown
                    items={(user.data?.naturalPerson?.AliasIBAN || [])?.map((o) => ({
                      value: o.BankInfoDto.Name + ' - ' + o.Iban.replace(/ /, ''),
                      key: o.UUID
                    }))}
                    placeholder={t('kypay.modal_withdraw_funds.input.bank_account_placeholder')}
                    search
                    onChange={({ key }) => {
                      setIban(key)
                    }}
                    initialValue={null}
                  />
                )}
                {!(user.data?.naturalPerson?.AliasIBAN || []).length && (
                  <Info>{t('kypay.modal_withdraw_funds.label.no_bank_account')}</Info>
                )}
              </>
            }
          />
          <div className="mb-6">
            <A to={Routes.account_identity}>
              {t('kypay.modal_withdraw_funds.label.add_bank_account')}
            </A>
          </div>

          <Button
            disabled={!amount || !amountString || !iban}
            onClick={async () => {
              if (amount) {
                await withdrawKypayBalance({ amount: amount * 100, alias_iban_uuid: iban })
                resetInputs()
                props.onClose?.()
              }
            }}
          >
            {t('kypay.button.withdraw')}
          </Button>
        </div>
      </ModalContent>
    </Modal>
  )
}
