import {UseMutationOptions, useMutation} from '@tanstack/react-query'
import { SecurityApiClient } from "../api-client/api-client";
import { TryConnectionParams, TryConnectionResponse } from "../types";


export default function useTryConnectionMutation(options?: UseMutationOptions<TryConnectionResponse, Error, TryConnectionParams>) {

    return useMutation<TryConnectionResponse, Error, TryConnectionParams>({mutationFn: async (params: TryConnectionParams) => {
        const res = await SecurityApiClient.tryConnectViaOTP(params.email, params.password, params.codeOtp);
        console.log('useTryConnectionMutation', res)
        if(!res.success) {
            throw new Error(res.data)
        }
        return res
    }, ...options})
}