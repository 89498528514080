import config from '@/config'
import {
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { WalletApiClient } from '../api-client/api-client'
import {
  CurrencyList,
  PortfolioList,
  sparklineDataProps,
  sparklineTotalDataProps,
  OrdersDataProps,
  sparklineDataReponse
} from '../types'

export function useWallet() {

  const currencyQuery = (options?: Omit<UseQueryOptions<CurrencyList>, 'queryKey'>) =>
    useQuery<CurrencyList>({
      queryKey: ['currency'],
      queryFn: async () => {
        return await WalletApiClient.getCurrencyList()
      },
      ...options
    })

  const portfolioQuery = (options?: Omit<UseQueryOptions<PortfolioList>, 'queryKey'>) =>
    useQuery<PortfolioList>({
      queryKey: ['portfolio'],
      queryFn: async () => {
        return await WalletApiClient.getPortfolioList()
      },
      ...options
    })

  const sparklineDataQuery = (
    sendData: sparklineDataProps,
    options?: Omit<UseQueryOptions<sparklineDataReponse>, 'queryKey'>
  ) =>
    useQuery<sparklineDataReponse>({
      queryKey: ['sparklineData', sendData],
      queryFn: async () => {
        return await WalletApiClient.sparklineData({sparkline: sendData})
      },
      ...options
    })

  const sparklineTotalDataQuery = (
    sendData: sparklineTotalDataProps,
    options?: Omit<UseQueryOptions<sparklineDataReponse>, 'queryKey'>
  ) =>
    useQuery<sparklineDataReponse>({
      queryKey: ['sparklineTotalData', sendData],
      queryFn: async () => {
        return await WalletApiClient.sparklineTotalData({sparkline: sendData})
      },
      ...options
    })

  const OrdersDataQuery = (
    sendData: OrdersDataProps,
    options?: Omit<UseQueryOptions<sparklineDataReponse>, 'queryKey'>
  ) =>
    useQuery<sparklineDataReponse>({
      queryKey: ['ordersData', sendData],
      queryFn: async () => {
        return await WalletApiClient.ordersData(sendData)
      },
      ...options
    })
    

  return {
    currencyQuery,
    portfolioQuery,
    sparklineDataQuery,
    sparklineTotalDataQuery,
    OrdersDataQuery,
  }
}
