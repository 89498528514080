'use client'
import { useAuth } from '@/features/auth/hooks/use-auth-react'
import { useKYCContext } from '@/features/auth/hooks/use-kyc-context'
import { useKypay } from '@/features/kypay/hooks/use-kypay'
import { CoinLayout } from '@/molecules/coin-layout/index.react'
import { KYCAlert } from '@/molecules/kyc-alert/index.react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LayoutTitle } from '../../../_layout/index.react'
import { CardWallet } from '../wallet-card/card-wallet.react'
import { TransactionsList } from '../transaction-list/transaction-list.react'
import { CardStack } from '../../../../../../molecules/card-stack/card-stack.react'
import { WalletNumber } from '../wallet-number/wallet-number.react'
import { useWallet } from '@/features/wallet/hooks/use-wallet'
import Loader from '@/atoms-react/loader/Loader.react'
import { image } from 'ionicons/icons'

export const WalletRoot = () => {
  const { t } = useTranslation(['wallet'])
  const [search, setSearch] = useState<string>('')
  
  const { currencyQuery, portfolioQuery, sparklineTotalDataQuery } = useWallet()
  const { data: portfolio, isLoading: isPortfolioLoading } = portfolioQuery()
  const { data: currency, isLoading: isCurrencyLoading } = currencyQuery()

  const { data: sparklineTotalData, isLoading: isSparklineTotalDataLoading } = sparklineTotalDataQuery({type: 'YEAR'})


  let totalAmount = 0
  let totalAmountRate = 0
  const chartData = []
  const calculateRealPrice = portfolio?.map((itemList) => {
    const cryptoData = currency?.find(item => item.symbol === itemList.crypto_currency.symbol)
    const currentAmount = cryptoData?.current_price * itemList.balance || 0
    totalAmount = totalAmount + currentAmount
    totalAmountRate = totalAmount > 0 ? currentAmount / totalAmount : 0
    chartData[itemList?.crypto_currency?.name] = currentAmount
    return {
      ...itemList,
      image: cryptoData?.image,
      currentAmount,
    }
  })

  
  const { kypayQuery } = useKypay()
  const { data: kypay, isLoading: isKypayLoading } = kypayQuery()
  const transactions = (kypay?.transaction ?? []).filter(
    (trans) => (trans.Counterparty ?? '').includes(search) || trans.formatedAmount.includes(search)
  )

  const walletIdentifiant = (<WalletNumber
                wallet='0454e403-6ead-407e-9c6c-30d53a70e783'
                label={t('wallet.dashboard.label.section_title')}
                className='flex items-center space-x-2 mb-2'
                copy
              />)

  return (
    <>
      <CoinLayout
        search={search}
        setSearch={setSearch}
        title={() => (
          <>
            <LayoutTitle 
              action={walletIdentifiant}
            >
              {t('wallet.dashboard.label.main_title')}
            </LayoutTitle>
          </>
        )}
        sectionTitle={''} // For the sub-title
        header={() => ''}
        body={() => (
          <div className="grid grid-cols-3 gap-4 w-full">
            {!isPortfolioLoading && !isCurrencyLoading &&  !isSparklineTotalDataLoading && (
              <>
                <CardWallet Type="total" className="col-span-2" config={{totalAmount, totalAmountRate, chartData: sparklineTotalData}} />
                <CardStackWallet />
                <CardWallet Type="repartition" className="col-span-3" config={{data: {calculateRealPrice, chartData}}} />
              </>
            )}
            {(isPortfolioLoading || isCurrencyLoading || isSparklineTotalDataLoading) && <Loader className={'w-8 h-8 text-blue-500'} />}
          </div>
        )}
        transactions={{
          title: t('kypay.section_title_transactions'),
          body: () => (
            <TransactionsList
              transactions={
                transactions.map((trans) => ({
                  title: trans.Counterparty,
                  amount: trans.NumberInt / 100,
                  type: trans.Type,
                  date: trans.Date
                })) ?? []
              }
              search={search}
            />
          )
        }}
      />
    </>
  )
}

export function CardStackWallet() {
  const { t } = useTranslation(['pass'])
  const CARDS = [
    {
      id: 0,
      title: t('olkypass.WalletDashboardPage.cardsExplanationsKypay.title.1'),
      content: t('olkypass.WalletDashboardPage.cardsExplanationsKypay.content.1')
    },
    {
      id: 1,
      title: t('olkypass.WalletDashboardPage.cardsExplanationsKypay.title.2'),
      content: t('olkypass.WalletDashboardPage.cardsExplanationsKypay.content.2')
    },
    {
      id: 2,
      title: t('olkypass.WalletDashboardPage.cardsExplanationsKypay.title.3'),
      content: t('olkypass.WalletDashboardPage.cardsExplanationsKypay.content.3')
    }
  ]
  return (
    <div className='flex items-center w-full max-w-lg'>
      <CardStack items={CARDS} />
    </div>
  )
}
