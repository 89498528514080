import {UseMutationOptions, useMutation} from '@tanstack/react-query'
import { SecurityApiClient } from "../api-client/api-client";
import { ValidationEmailCodeParams, ValidationEmailCodeResponse } from "../types";


export default function useValidationEmailMutation(options?: UseMutationOptions<ValidationEmailCodeResponse, Error, ValidationEmailCodeParams>) {

    return useMutation<ValidationEmailCodeResponse, Error, ValidationEmailCodeParams>({mutationFn: async (params: ValidationEmailCodeParams) => {        
        const res = await SecurityApiClient.checkEmailCode(params.cypher, params.codeEmail, params.actionType);
        console.log('useValidationEmailMutation', res)
        if(!res.success) {
            throw new Error(res.data)
        }
        return res        
    }, ...options})
}